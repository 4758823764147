// Import the necessary dependencies
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
    setIsAuthenticated,
    setLoginData,
    setTokens,
    setUser,
    setUserData,
} from '../../store/auth.slice'
import {
    getOTP,
    getUser,
    getUserByAccessToken,
    login,
} from '../../services/auth.service'
import {
    Button,
    Input,
    VStack,
    CardBody,
    Heading,
    CardHeader,
    Text,
} from '@chakra-ui/react'
import { AuthCard } from '../../components/ui'
import AuthLayout from '../../layouts/AuthLayout'
import { LoginForm, UserData } from '../../types'
import { useNavigate } from 'react-router-dom'
import { AppRoutes, toast, validateMembershipId } from '../../utils'
import { AxiosResponse } from 'axios'
import { useURIQuery } from '../../hooks/useQuery'

export function Login() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // Use react-hook-form to manage form state and validation
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>()
    const query = useURIQuery()
    const id = query.get('memberid')
    const queryAccessToken = query.get('access')
    const queryRefreshToken = query.get('refresh') || ''

    useEffect(() => {
        if (id) {
            loginWithoutOTP(id)
        }
        if (queryAccessToken) {
            loginWithAccessToken(queryAccessToken, queryRefreshToken)
        }
        // console.log('Member id: ' + id)
    }, [id])

    const onSubmit = async (data: LoginForm) => {
        const { memberId } = data
        setLoading(true)
        try {
            const result = await getOTP(memberId)
            console.log('OTP Result:', result)

            toast({
                title: 'OTP Sent',
                description: `Check ${result.user_number} for Code`,
                status: 'success',
            })

            dispatch(
                setLoginData({
                    memberId,
                    phoneNumber: result.user_number,
                })
            )

            // Handle navigation or other logic based on the result
            // You can navigate to the OTP verification page if needed
            navigate(AppRoutes.OTP)
        } catch (error) {
            console.error('Error during OTP retrieval:', error)
            // Display error as a toast
            toast({
                title: 'Error',
                description:
                    'Failed to retrieve OTP. Please check your Membership ID.',
                status: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const loginWithoutOTP = async (memberId: string) => {
        // Validation checks

        // Validation checks
        if (!validateMembershipId(memberId)) {
            return
        }

        setLoading(true)
        try {
            const objData = await login(memberId)
            const responseData = objData

            const access = responseData.access
            const refresh = responseData.refresh

            dispatch(setTokens({ accessToken: access, refreshToken: refresh }))
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
            dispatch(setIsAuthenticated(true))

            const userDataResponse: AxiosResponse<UserData> = await getUser()
            const userData: UserData = userDataResponse.data
            dispatch(
                setUser({
                    firstName: userData.firstname,
                    lastName: userData.lastname,
                    email: userData.email || '',
                    phoneNumber: userData.mobilephone,
                    memberId: userData.membernumber,
                })
            )
            dispatch(setUserData(userData))
            console.log(userData)
            setLoading(false)
            navigate(AppRoutes.HOME)
        } catch (error) {
            console.error('Authentication failed:', error)
            toast({
                title: 'Authentication Failed',
                description: 'Try again',
                status: 'warning',
            })
        } finally {
            setLoading(false)
        }
    }

    const loginWithAccessToken = async (
        accessToken: string,
        refreshToken: string
    ) => {
        if (accessToken) {
            setLoading(true)

            try {
                const userDataResponse: AxiosResponse<UserData> =
                    await getUserByAccessToken(accessToken)
                const userData: UserData = userDataResponse.data
                dispatch(
                    setUser({
                        firstName: userData.firstname,
                        lastName: userData.lastname,
                        email: userData.email || '',
                        phoneNumber: userData.mobilephone,
                        memberId: userData.membernumber,
                    })
                )
                dispatch(setTokens({ accessToken, refreshToken }))
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                dispatch(setIsAuthenticated(true))
                dispatch(setUserData(userData))
                console.log(userData)
                setLoading(false)
                navigate(AppRoutes.HOME)
            } catch (error) {
                console.error('Authentication failed:', error)
                toast({
                    title: 'Authentication Failed',
                    description: 'Try again',
                    status: 'warning',
                })
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <AuthLayout>
            <AuthCard>
                <CardHeader textAlign="center">
                    <Heading
                        fontSize="30px"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Welcome
                    </Heading>
                    <Text fontSize="16px" mt="8px">
                        Please login to continue
                    </Text>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <VStack>
                            {/* Input field with registration and validation */}
                            <Input
                                placeholder="Membership No."
                                variant="primaryInput"
                                {...register('memberId', {
                                    required: 'Membership ID is required',
                                    // Add other validation rules as needed
                                })}
                            />
                            {/* Display errors as toast */}
                            {errors.memberId &&
                                toast({
                                    title: 'Error',
                                    description: errors.memberId.message,
                                    status: 'warning',
                                })}
                            {/* Submit button */}
                            <Button
                                type="submit"
                                variant="primary"
                                isLoading={loading}
                            >
                                LOGIN
                            </Button>
                        </VStack>
                    </form>
                </CardBody>
            </AuthCard>
        </AuthLayout>
    )
}
