import { ServiceEndpoints } from '../utils'
import { axios } from './apiConfig'

interface UserBenefitsPayload {
    type: string // Adjust the properties as needed
    company: string
    iplan: string
    // Add other properties as needed
}

export const getUserBenefits = async (payload: UserBenefitsPayload) => {
    try {
        const response = await axios.post(ServiceEndpoints.GETALLBENEFITS, payload)
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getNextBenefits = async (
    url: string,
    payload: UserBenefitsPayload
) => {
    try {
        const response = await axios.post(url, payload)
        return Promise.resolve(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getBenefitsGraph = async (member_id : string) => {
    try {
        const response = await axios.post(
            `${ServiceEndpoints.GETGRAPHDATA}`,
            { membernumber: member_id }
        );
        return Promise.resolve(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};