import React, { useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import {
    Center,
    Flex,
    Heading,
    Icon,
    Spinner,
    Text,
    chakra,
    useDisclosure,
} from '@chakra-ui/react'
import { BsClipboardPulse } from 'react-icons/bs'
import { DrugsModal, HistoryCard } from './_components'
import { IoCashOutline } from 'react-icons/io5'
import { FaStethoscope } from 'react-icons/fa6'
import { GiHospitalCross } from 'react-icons/gi'
import { PiPillFill } from 'react-icons/pi'
import { useURIQuery } from '../../hooks/useQuery'
import { getByKeyValue, getDate, getTotalAmt } from '../../utils'
import { HistoryDetail } from '../../types'
import { getHistoryDetails } from '../../services/history.service'

export const HistoryDetailsPage = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [totalAmt, setTotalAmt] = useState(0)
    const [drugs, setDrugs] = useState<HistoryDetail[]>([])
    const [state, setState] = useState({
        hsp: '',
        ailment: '',
        diagnostic: '',
    })
    // const drugs = ['Nexium Table 40mg', 'NUGEL O SUSP']
    const drug_modal = useDisclosure()
    const query = useURIQuery()
    const date = query.get('date')

    React.useEffect(() => {
        asyncGetHistoryDetails()
    }, [])

    async function asyncGetHistoryDetails() {
        try {
            const objData = await getHistoryDetails(date)
            const history = objData.data
            console.log(history)
            setLoading(false)
            setTotalAmt(getTotalAmt(history))
            const _drugs = getByKeyValue(history, 'Drugs')
            console.log(_drugs)
            setDrugs(_drugs)
            setState({ ...state, ...history[0] })
        } catch (error) {
            console.log('error')
            // console.log(error.response)
            setLoading(false)
        }
    }

    return (
        <DashboardLayout showNavbar={false} pageTitle="History Details">
            <Flex flexDir="column" gap={4}>
                {loading ? (
                    <Center h="75vh">
                        <Spinner color="purple.800" />
                    </Center>
                ) : (
                    <>
                        <Flex
                            flexDir="row"
                            minH="16.6dvh"
                            borderRadius={20}
                            bg="secondary.400"
                            gap={5}
                            alignItems="center"
                            px={6}
                            py={8}
                        >
                            <Icon
                                as={BsClipboardPulse}
                                boxSize="10vh"
                                color="white"
                            />
                            <Flex flexDir="column" gap={1} textColor="white">
                                <Heading size="sm">{getDate(date)}</Heading>
                                <Text textTransform="uppercase">
                                    {state.hsp}
                                </Text>
                            </Flex>
                        </Flex>

                        <HistoryCard
                            title="Total Amount Spent"
                            icon={IoCashOutline}
                        >
                            <Text fontWeight={600} style={{ color: 'gray' }}>
                                GH{'\u20B5'} {totalAmt}
                            </Text>
                        </HistoryCard>
                        <HistoryCard title="Diagnostic" icon={FaStethoscope}>
                            <Text style={{ color: 'gray' }}>
                                {state.diagnostic}
                            </Text>
                        </HistoryCard>
                        <HistoryCard title="Ailment" icon={GiHospitalCross}>
                            <Text style={{ color: 'gray' }}>
                                {state.ailment}
                            </Text>
                        </HistoryCard>
                        <DrugsModal
                            isOpen={drug_modal.isOpen}
                            onClose={drug_modal.onClose}
                            data={drugs}
                        />
                        {drugs.length ? (
                            <HistoryCard
                                title="Drugs"
                                icon={PiPillFill}
                                onClick={() => drug_modal.onOpen()}
                            >
                                <Text style={{ color: 'gray' }}>
                                    Number of Drugs: {drugs.length}.{' '}
                                    <chakra.span
                                        textDecorationLine="underline"
                                        fontWeight="medium"
                                        fontStyle="italic"
                                        color="secondary.400"
                                    >
                                        Click to view drugs
                                    </chakra.span>
                                </Text>
                            </HistoryCard>
                        ) : null}
                    </>
                )}
            </Flex>
        </DashboardLayout>
    )
}
