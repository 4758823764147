import React from 'react'
import { Flex, Image, Text } from '@chakra-ui/react'
import { drugImage } from '../../../utils'
import { motion } from 'framer-motion'

export function EmptyMedication() {
    return (
        <Flex
            as={motion.div}
            initial={{ opacity: 0.2, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            // @ts-expect-error, works
            transition={{
                duration: 0.8,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                    type: 'spring',
                    damping: 5,
                    stiffness: 100,
                    restDelta: 0.001,
                },
            }}
            flex={1}
            alignItems="center"
            justifyContent="center"
            h="70dvh"
        >
            <Image src={drugImage} h="70" w="70" />
            <Text>No medication found</Text>
        </Flex>
    )
}
