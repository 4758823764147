import {
    Avatar,
    Box,
    Button,
    HStack,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react'
import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { menuItems } from './constants'
import { useSelector } from '../../hooks/useSelector'
import { FaCalendarAlt, FaMapMarkerAlt, FaSignOutAlt } from 'react-icons/fa'
import { FaBuilding, FaHospital, FaPhone } from 'react-icons/fa6'
import { PiClockClockwise } from 'react-icons/pi'
import moment from 'moment'
import ProfileInfoStack from '../../components/ui/Cards/ProfileStack'
import UserBodyStack from '../../components/ui/Cards/UserBodyStack'
import { decodeImage } from '../../utils'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLogout } from '../../store/auth.slice'

export function Profile() {
    const userData = useSelector((state) => state.auth.userData)
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const logout = () => {
        dispatch(setLogout())
    }

    // const encodedString = userData.imgperspic

    // const decodedImage = decodeImage(`${encodedString}`)

    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Profile"
        >
            <Box w="100vw">
                <VStack spacing={4} p={5} mb={4} align="start">
                    <HStack spacing={2}>
                        <HStack spacing={4}>
                            <Avatar
                                size="lg"
                                src={
                                    `${decodeImage(`${userData.imgperspic}`)}` ||
                                    ''
                                }
                            />
                            <VStack align="start">
                                <Text
                                    fontSize="xl"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                >
                                    {userData.firstname.toLowerCase()}{' '}
                                    {userData.lastname.toLowerCase()}
                                </Text>
                                <Text color="gray.600">
                                    {userData.membernumber}
                                </Text>
                            </VStack>
                        </HStack>
                    </HStack>
                </VStack>

                <VStack align="start" spacing={4} px={30} color="gray.500">
                    <VStack align="start" spacing={3}>
                        <ProfileInfoStack
                            icon={FaMapMarkerAlt}
                            text={userData.branch}
                        />
                        <ProfileInfoStack
                            icon={FaPhone}
                            text={userData.mobilephone}
                        />
                        <ProfileInfoStack
                            icon={FaBuilding}
                            text={userData.employer}
                        />
                        <ProfileInfoStack
                            icon={FaHospital}
                            text={`Insurance Plan - ${userData.planname}`}
                        />
                        <ProfileInfoStack
                            icon={FaCalendarAlt}
                            text={`Insurance Expiry Date - ${moment(userData.enddate).format('MMMM Do YYYY')}`}
                        />
                        <ProfileInfoStack
                            icon={PiClockClockwise}
                            text={`Your insurance ends - ${moment(userData.enddate).fromNow()}`}
                            color="secondary.400"
                            bold
                        />
                    </VStack>
                </VStack>

                <HStack spacing={0} alignItems="center" my={8}>
                    <UserBodyStack
                        label="Blood Group"
                        value={userData.bloodGroup || ''}
                    />
                    <UserBodyStack
                        label="Weight"
                        value={userData.height || ''}
                    />
                    <UserBodyStack
                        label="Height"
                        value={userData.height || ''}
                    />
                </HStack>

                <VStack spacing={4} p={4} align="start">
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.navigateTo?.startsWith('http') ? (
                                <a
                                    href={item.navigateTo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        variant="menuItems"
                                        fontSize="13px"
                                        leftIcon={
                                            <Icon
                                                as={item.icon}
                                                color="secondary.400"
                                                boxSize={5}
                                                mr={3}
                                            />
                                        }
                                    >
                                        {item.label}
                                    </Button>
                                </a>
                            ) : (
                                <Link to={item.navigateTo || ''}>
                                    <Button
                                        variant="menuItems"
                                        fontSize="13px"
                                        leftIcon={
                                            <Icon
                                                as={item.icon}
                                                color="secondary.400"
                                                boxSize={5}
                                                mr={3}
                                            />
                                        }
                                    >
                                        {item.label}
                                    </Button>
                                </Link>
                            )}
                        </React.Fragment>
                    ))}
                    <Button
                        onClick={logout}
                        variant="menuItems"
                        fontSize="13px"
                        leftIcon={
                            <Icon
                                as={FaSignOutAlt}
                                color="secondary.400"
                                boxSize={5}
                                mr={3}
                            />
                        }
                    >
                        Logout
                    </Button>
                </VStack>
            </Box>
        </DashboardLayout>
    )
}
