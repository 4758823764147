import React from 'react'
import { Box, Center, Spinner, Text, VStack } from '@chakra-ui/react'
import BenefitsCard from './BenefitsCard'
import {
    getNextBenefits,
    getUserBenefits,
} from '../../../services/benefits.service'
import { useSelector } from '../../../hooks/useSelector'
import { useQuery } from '@tanstack/react-query'
import { BenefitsApiResponse } from '../../../types'

type Payload = {
    type: string
    company: string
    iplan: string
}

const BenefitsList: React.FC = () => {
    const user = useSelector((state) => state.auth.userData)

    const payload: Payload = {
        type: user.relationship,
        company: user.employer,
        iplan: user.plans,
    }

    const { data, isFetching, isError } = useQuery<BenefitsApiResponse, Error>({
        queryKey: ['benefitsList'],
        queryFn: () => getUserBenefits(payload),
        // refetchInterval: 30000,
        // refetchOnWindowFocus: false,
        // staleTime: 60000,
    })

    const loadMoreBenefits = async () => {
        if (data?.links.next) {
            await getNextBenefits(data.links.next, payload)
        }
    }
    return (
        <Box>
            {isFetching && !data ? (
                <Center h="20vh">
                    <Spinner size="lg" color="purple.700" />
                </Center>
            ) : isError ? (
                <Text>Error fetching data</Text>
            ) : (
                <VStack gap={3}>
                    {data?.results.map((result) => (
                        <BenefitsCard item={result} key={result.caps} />
                    ))}
                    {data?.links.next && (
                        <div ref={loadMoreBenefits}>
                            {/* Sentinel element for intersection observer */}
                        </div>
                    )}
                </VStack>
            )}
        </Box>
    )
}

export default BenefitsList
