import React from 'react'
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react'

export const DependantLoader = () => {
    const loaderItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (
        <Flex flexDir="column">
            {loaderItems.map((item) => {
                return (
                    <Flex py={4} key={item} gap={4}>
                        <SkeletonCircle height="40px" w="40px" />
                        <Flex flexDir="column" key={item} gap={4}>
                            <Skeleton height="16px" w="70vw" />
                            <Skeleton height="8px" w="70dvw" />
                        </Flex>
                    </Flex>
                )
            })}
        </Flex>
    )
}
