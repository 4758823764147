import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Flex } from '@chakra-ui/react'

export function Chat() {
    window.$zoho = window.$zoho || {}
    window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode:
            'siq9a7aa09cd12f9f0717cb71241a36acc448b1fad52e3a2d51aec3829ce0c7c7c5',
        values: {},
        ready: function () {},
    }
    const d = document
    const s = d.createElement('script')
    s.type = 'text/javascript'
    s.id = 'zsiqscript'
    s.defer = true
    s.src = 'https://salesiq.zohopublic.com/widget'

    const t = d.getElementsByTagName('script')[0]
    t?.parentNode?.insertBefore(s, t)
    return (
        <React.Fragment>
            <DashboardLayout
                paddingX={false}
                showNavbar={false}
                pageTitle="Chat"
            >
                <Flex
                    flexDir="column"
                    minH="100dvh"
                    gap={7}
                    px={6}
                    justifyContent="start"
                    alignItems="center"
                    w={['100%', '80%']}
                    mx={['0', 'auto']}
                >
                    <div id="zsiqwidget"></div>
                </Flex>
            </DashboardLayout>
        </React.Fragment>
    )
}
