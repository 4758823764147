import { FaUserCheck } from 'react-icons/fa'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { LuAlarmClock } from 'react-icons/lu'
import { TbFileText } from 'react-icons/tb'
import { AppRoutes } from '../../utils'
import { API_DOMAIN } from '../../constants/config'

export const menuItems = [
    {
        icon: TbFileText,
        label: 'Terms of usage',
        navigateTo: `${API_DOMAIN}termsandcondtions/page/`,
    },
    {
        icon: IoShieldCheckmarkSharp,
        label: 'Privacy Policy',
        navigateTo: `${API_DOMAIN}policy/`,
    },
    {
        icon: LuAlarmClock,
        label: 'Notifications',
        onPress: () => {
            console.log('Notifications')
        },
    },
    { icon: FaUserCheck, label: 'Support', navigateTo: AppRoutes.SUPPORT },
]
