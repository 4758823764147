import React from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { FaLocationPin } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { AppRoutes, getDate } from '../../../utils'
import { HistoryDetail } from '../../../types'

export const HistoryItem = ({ data }: { data: HistoryDetail }) => {
    return (
        <Link to={`${AppRoutes.HISTORY_DETAILS}?date=${data.time}`}>
            <Flex
                flexDir="row"
                gap={4}
                px={4}
                pb={3}
                borderBottom="1px"
                borderBottomColor="gray.100"
            >
                <Icon as={FaLocationPin} color="secondary.400" fontSize={32} />
                <Flex flexDir="column" gap={1}>
                    <Text textTransform="uppercase" fontWeight={600}>
                        {data.hsp}
                    </Text>
                    <Text>{getDate(data.time)}</Text>
                </Flex>
            </Flex>
        </Link>
    )
}
