import { toast } from './toastHandler'

export const validateMembershipId = (memberId: string) => {
    if (!memberId) {
        toast({
            title: 'Membership ID Missing',
            description: 'Please Enter your Membership ID',
            status: 'error',
        })
        return false
    }
    return true
}

export const validateOTP = (formattedOTP: string) => {
    if (!formattedOTP.trim() || !/^\d{6}$/.test(formattedOTP)) {
        toast({
            title: 'OTP Error',
            description: 'Please Enter a valid 6-digit OTP',
            status: 'error',
        })
        return false
    }
    return true
}
