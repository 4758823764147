import React, { useEffect } from 'react'

export function Chatbot() {
    useEffect(() => {
        const handleWindowLoad = () => {
            const chatBtn = document.getElementById('zsiq_float')
            if (chatBtn) {
                chatBtn.click()
            }
        }

        if (document.readyState === 'complete') {
            handleWindowLoad()
        } else {
            window.addEventListener('DOMContentLoaded', handleWindowLoad)
        }

        return () => {
            window.removeEventListener('DOMContentLoaded', handleWindowLoad)
        }
    }, [])
    return <div></div>
}
