import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Medication } from '../types'
import moment from 'moment'

export interface MedicationState {
    medicine: Medication
    medications: Medication[]
    error: string | null
    index: number
    // memberId: string // Add memberId
}

const initialState: MedicationState = {
    medications: [],
    medicine: {
        dosage: 1,
        interval: 1,
        //duration: "everyday",
        alarm: true,
        type: 'Tablet',
        name: '',
        start_time: new Date(moment().format('ddd MMM DD YYYY HH:mm:ss ZZ')), //time user started taking medicine
        reminders: [
            {
                time: new Date(moment().format('ddd MMM DD YYYY HH:mm:ss ZZ')),
                id: '',
            },
        ],
    },
    error: null,
    index: 0,
}

const medicationSlice = createSlice({
    name: 'medication',
    initialState,
    reducers: {
        setMedication: (state, action: PayloadAction<number>) => {
            const items = state.medications
            state.index = action.payload
            state.medicine = items[action.payload]
        },
        addMedication: (state, action: PayloadAction<Medication>) => {
            state.medications.push(action.payload)
        },
        emptyMedications: (state) => {
            state.medications = []
        },
        deleteMedication: (state, action: PayloadAction<number>) => {
            const items = state.medications
            const medicines = items
                .slice(0, action.payload)
                .concat(items.slice(action.payload + 1, items.length))
            state.medications = medicines
        },
        changeMedicationNotification: (
            state,
            action: PayloadAction<Medication>
        ) => {
            state.medications.push(action.payload)
        },
        updateMedication: (state, action: PayloadAction<Medication>) => {
            state.medications[state.index] = action.payload
        },
        updateAllMedications: (state) => {
            state.medications = initialState.medications
        },
    },
})

export const {
    setMedication,
    addMedication,
    emptyMedications,
    deleteMedication,
    changeMedicationNotification,
    updateMedication,
    updateAllMedications,
} = medicationSlice.actions

export default medicationSlice.reducer
