import { Box, Card, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { Benefit } from '../../../types'

interface BenefitsCardProps {
    item: Benefit
}

const BenefitsCard: React.FC<BenefitsCardProps> = ({ item }) => {
    return (
        <Card
            borderBottom="0.5px solid #DADCE0"
            p={5}
            pr={12}
            variant="unstyled"
            w="96vw"
            borderRadius={0}
        >
            <Flex align="center" justify="space-between" w={{ base: 'full' }}>
                <Box>
                    <Text textTransform="uppercase" mb={1} fontSize="15px">
                        {item.caps}
                    </Text>
                    <Text fontWeight="700" fontSize="12px">
                        GH{'\u20B5'} {item.amt}
                    </Text>
                </Box>
                <Text color="purple.600" fontSize="13px">
                    2023
                </Text>
            </Flex>
        </Card>
    )
}

export default BenefitsCard
