import {
    Box,
    BoxProps,
    Flex,
    FlexProps,
    Icon,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import {
    IoAccessibilityOutline,
    IoCardOutline,
    IoCashOutline,
    IoGiftOutline,
    IoLogOutOutline,
    IoManOutline,
    IoMedkitOutline,
    IoPersonOutline,
} from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { setLogout } from '../../../store/auth.slice'
import { UserInfo } from '../UserInfo'
import { IconType } from 'react-icons'
import { ReactText } from 'react'
import { FaClockRotateLeft } from 'react-icons/fa6'
import { BsFileEarmarkText } from 'react-icons/bs'
import { LuHome } from 'react-icons/lu'
import { AppRoutes } from '../../../utils/constants'
import { PiChatsDuotone } from 'react-icons/pi'

interface LinkItemProps {
    name: string
    to?: string
    icon?: IconType
}

// array of menu routes or link items
const LinkItems: Array<LinkItemProps> = [
    { name: 'Home', icon: LuHome, to: AppRoutes.HOME },
    { name: 'Profile', icon: IoPersonOutline, to: AppRoutes.PROFILE },
    { name: 'History', icon: FaClockRotateLeft, to: AppRoutes.HISTORY },
    { name: 'Claims Refund', icon: IoCashOutline, to: AppRoutes.CLAIMS },
    {
        name: 'My Dependants',
        icon: IoAccessibilityOutline,
        to: AppRoutes.DEPENDANTS,
    },
    {
        name: 'Medication Request',
        icon: IoMedkitOutline,
        to: AppRoutes.MEDICATION_REQUEST,
    },
    { name: 'Exclusions', icon: IoManOutline, to: AppRoutes.EXCLUSIONS },
    { name: 'Our Benefits', icon: IoGiftOutline, to: AppRoutes.BENEFITS },
    { name: 'Health Quiz', icon: BsFileEarmarkText, to: AppRoutes.HEALTH_QUIZ },
    { name: 'Support', icon: PiChatsDuotone, to: AppRoutes.SUPPORT },
    { name: 'E-Card', icon: IoCardOutline, to: AppRoutes.E_CARD },
]

export const SideBarContent = ({ ...rest }: BoxProps) => {
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(setLogout())
    }

    const location = useLocation()

    //destructuring pathname from location
    const { pathname } = location

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split('/')

    const splitURL = (data: string) => {
        return data.split('/')[1]
    }

    return (
        <Flex
            transition="0.3s"
            shadow="md"
            bg="white"
            w={{ base: '60', md: 64 }}
            position="fixed"
            flexDirection={'column'}
            zIndex="4"
            gap={4}
            h={'full'}
            justifyContent={'space-between'}
            {...rest}
            height={'100dvh'}
        >
            <Flex
                flexDir={'column'}
                gap={4}
                justifyContent={'start'}
                h={{ base: '85vh', md: '100%' }}
            >
                <UserInfo />
                <Flex
                    flexDir={'column'}
                    justifyContent={'space-between'}
                    overflowY={{ base: 'scroll', lg: 'hidden' }}
                >
                    {LinkItems.map((link, index) => (
                        <Box
                            key={link.name + index}
                            _hover={{ backgroundColor: 'secondary.300' }}
                        >
                            {link?.to ? (
                                <NavItem
                                    icon={link.icon}
                                    to={link.to}
                                    fontFamily={'inter'}
                                    mb="2px"
                                    bg={
                                        splitLocation[1] === splitURL(link.to)
                                            ? 'gray.200'
                                            : 'transparent'
                                    }
                                >
                                    {link.name}
                                </NavItem>
                            ) : (
                                <Flex align="center" px="4" pt="4" mx="4">
                                    <Icon mr="4" fontSize="16" as={link.icon} />
                                    <Text>{link.name}</Text>
                                </Flex>
                            )}
                        </Box>
                    ))}
                </Flex>
            </Flex>

            <Flex
                bg="white"
                align="center"
                // bottom="0"
                w={'100%'}
                borderTop={'1px'}
                borderColor={'gray.300'}
                px={{ base: 4, md: 8 }}
                mb={0}
                pt={3}
                pb={6}
                role="group"
                cursor="pointer"
                onClick={() => logout()}
            >
                <Icon mr="4" fontSize="20" as={IoLogOutOutline} />
                <Text color="gray.600" fontFamily={'inter'} fontSize="14px">
                    Logout
                </Text>
            </Flex>
        </Flex>
    )
}

interface NavItemProps extends FlexProps {
    icon?: IconType
    to: string
    children: ReactText
}

const NavItem = ({ icon, to, children, ...rest }: NavItemProps) => {
    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <Flex
                color="gray.600"
                align="center"
                px={{ base: 2, md: 4 }}
                py="3"
                mx={{ base: 2, md: '4' }}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                fontSize="14px"
                _hover={{
                    backgroundColor: useColorModeValue('gray.200', 'bg.500'),
                }}
                {...rest}
            >
                {icon && <Icon mr="4" fontSize="20" as={icon} />}
                {children}
            </Flex>
        </Link>
    )
}
