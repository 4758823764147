/* eslint-disable */
// @ts-nocheck

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateWrapper = ({
    children,
    auth,
    noAuth,
}: {
    children: JSX.Element
    auth?: Boolean
    noAuth?: Boolean
}) => {
    const accessToken = useSelector((state) => state.auth.accessToken)
    if (noAuth) {
        return !accessToken ? children : <Navigate to="/" replace />
    }
    if (!auth) {
        return children
    }
    return accessToken ? children : <Navigate to="/login" replace />
}
export default PrivateWrapper
