import { Box, VStack } from '@chakra-ui/react'
import React from 'react'
import CardDetailInfo from './CardDetailInfo'
import { useSelector } from '../../../hooks/useSelector'
import moment from 'moment'

const CardDetails = () => {
    const user = useSelector((state) => state.auth.userData)
    return (
        <Box position="absolute" top="35%" right={5}>
            <VStack align="start">
                <CardDetailInfo
                    label="Name"
                    value={user.firstname + ' ' + user.lastname}
                />
                <CardDetailInfo
                    label="Date of Birth"
                    value={moment(user.dob).format('DD/MM/YYYY')}
                />
                <CardDetailInfo
                    label="Policy Begins"
                    value={moment(user.startdate).format('DD/MM/YYYY')}
                />
                <CardDetailInfo
                    label="Member Number"
                    value={user.membernumber}
                />
                <CardDetailInfo label="Firm" value={user.employer} />
            </VStack>
        </Box>
    )
}

export default CardDetails
