import { ServiceEndpoints } from '../utils'
import { axios } from './apiConfig'

export const getHealthTips = async () => {
    try {
        const response = await axios.get(ServiceEndpoints.HEALTH_TIPS)
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}
