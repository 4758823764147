import React from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'

export const HistoryLoader = () => {
    const loaderItems = [1, 2, 3, 4, 5, 6]

    return (
        <Flex flexDir="column">
            {loaderItems.map((item) => {
                return (
                    <Flex py={4} key={item} gap={4}>
                        <Skeleton height="40px" w="40px" />
                        <Flex flexDir="column" key={item} gap={4}>
                            <Skeleton height="16px" w="180px" />
                            <Skeleton height="8px" w="250px" />
                        </Flex>
                    </Flex>
                )
            })}
        </Flex>
    )
}
