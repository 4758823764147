import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io'

interface ImageItemProps {
    uri: string
    image: string
    deleteItem: () => void
}

export function ImageItem({ uri, image, deleteItem }: ImageItemProps) {
    const [imageName, setImageName] = useState('')
    const getImageName = () => {
        if (uri) {
            const filename = uri.substring(uri.lastIndexOf('/') + 1)
            // console.log('File:  ' + filename)
            setImageName(filename)
            return filename
        }
    }

    useEffect(() => {
        getImageName()
    }, [])

    return (
        <Box>
            <Flex
                flexDirection="row"
                backgroundColor="white"
                justifyContent="center"
                alignItems="center"
                px={1}
                borderRadius={2}
            >
                <Flex>
                    <Image src={image} flex="1" height="63" width="62px" />
                </Flex>
                <Flex py={5} pl={3}>
                    <Text color="#707070A8" fontSize="14">
                        {imageName}
                    </Text>
                </Flex>
                <Flex
                    onClick={() => deleteItem()}
                    style={{
                        flex: 2,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Icon as={IoMdClose} size={14} color="danger" />
                </Flex>
            </Flex>
        </Box>
    )
}
