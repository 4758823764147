import {
  FaEarthAfrica,
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaPhone,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import { IoMdInformationCircle } from "react-icons/io";
import { GiRotaryPhone } from "react-icons/gi";
import { MdEmail } from "react-icons/md";

export const contacts = [
  {
    name: "Phone",
    icon: FaPhone,
    description: "059 692 1844",
    url: "tel:0200132103",
  },
  {
    name: "Whatsapp",
    icon: FaWhatsapp,
    description: "020 013 2103",
    url: "whatsapp://send?phone=0200132103",
  },
  {
    name: "Information",
    icon: IoMdInformationCircle,
    description: "Read more About Us",
    route: true,
    url: "about-us",
    textStyle: {
      textDecorationLine: "underline",
    },
  },
  {
    name: "Landline",
    icon: GiRotaryPhone,
    description: "0302 543 738",
  },
  {
    name: "Address",
    icon: FaLocationDot,
    description: "Plot 220 Garden Road – East Legon \nGPcode: GA-412-4404",
  },
  {
    name: "Email",
    icon: MdEmail,
    description: " info.acacia@myenterprisegroup.io",
  },
  {
    name: "web",
    icon: FaEarthAfrica,
    description: "www.myenterprisegroup.io/acacia/",
    url: "https://www.myenterprisegroup.io/acacia/",
    textStyle: {
      textDecorationLine: "underline",
    },
  },
];

export const socialMediaIcons = [
  {
    name: "Facebook",
    icon: FaFacebook,
    url: "https://www.facebook.com/acaciahealthinsurance",
    color: "blue",
  },
  {
    name: "Instagram",
    icon: FaInstagram,
    url: "https://www.instagram.com/acaciahealthinsurance/",
    color: "pink",
  },
  {
    name: "Twitter",
    icon: FaTwitter,
    url: "https://www.twitter.com/AcaciaHealth2",
    color: "#00acee",
  },
];
