import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    AspectRatio,
    VStack,
    Text,
    HStack,
    Icon,
    Box,
} from '@chakra-ui/react'
import { FaPhone, FaX } from 'react-icons/fa6'
import { commonTextStyle } from '../constants'
import { useSelector } from '../../../hooks/useSelector'


interface LocationModalProps {
    isOpen: boolean
    onClose: () => void
}

const LocationModal = ({ isOpen, onClose }: LocationModalProps) => {
    const displayedHospital = useSelector(
        (state) => state.hospital.selectedHospital
    )
    // console.log(displayedHospital)

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent w="90%" p={2}>
                <ModalCloseButton />
                <ModalBody p={0}>
                    {/* TO DO: Dynamically Display Map */}
                    <AspectRatio ratio={6 / 8}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.8538282777017!2d-0.1866616243223672!3d5.5886106333195125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9af427d8e49f%3A0xabf4a7f91a3e2449!2s37%20Military%20Teaching%20Hospital!5e0!3m2!1sen!2sgh!4v1707907139592!5m2!1sen!2sgh"
                            width="600"
                            height="450"
                            loading="lazy"
                        ></iframe>
                    </AspectRatio>
                    <VStack align="start" p={5}>
                        <Text {...commonTextStyle(500, 20, 'black')}>
                            {displayedHospital.name}
                        </Text>
                        <Text {...commonTextStyle(700, 14, 'black')}>
                            {displayedHospital.loc}
                        </Text>
                        <Text {...commonTextStyle(700, 15, 'purple.700')}>
                            {displayedHospital.spec}
                        </Text>
                        <Text {...commonTextStyle(500, 15, 'purple.700')}>
                            Health Plan: {displayedHospital.hplan}
                        </Text>
                        <HStack>
                            <Icon as={FaPhone} color="purple.500" />
                            <Text {...commonTextStyle(500, 15, 'purple.700')}>
                                {displayedHospital.contact}
                            </Text>
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Box mx="auto">
                        <Button
                            as={FaX}
                            borderRadius="50%"
                            bg="tertiary.100"
                            color="white"
                            p={3}
                            boxSize={10}
                            onClick={onClose}
                        />
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default LocationModal
