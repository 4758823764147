import React from 'react'
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react'
import { HistoryDetail } from '../../../types'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../utils'

interface DrugsModalProps {
    isOpen: boolean
    onClose: () => void
    data: HistoryDetail[]
}

export const DrugsModal = ({ isOpen, onClose, data }: DrugsModalProps) => {
    const navigate = useNavigate()
    const addDrug = (name: string) => {
        onClose()
        navigate(`${AppRoutes.ADD_MEDICATION}?name=${name}`)
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size="sm"
                // borderRadius={6}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="medium" fontSize="14px">
                        Add drugs to your intake
                    </ModalHeader>
                    <ModalBody>
                        <Flex flexDir="column" gap={2}>
                            {data.map((item, key) => {
                                return (
                                    <Flex
                                        key={key}
                                        justifyContent="space-between"
                                        alignItems="center"
                                        borderBottomWidth="1px"
                                        borderBottomColor="gray.100"
                                        pb={1}
                                        aria-label={item.description}
                                        onClick={() =>
                                            addDrug(item.description)
                                        }
                                    >
                                        <Text>{item.description}</Text>
                                        <Button
                                            variant="secondary"
                                            py={1}
                                            size="xs"
                                        >
                                            Add
                                        </Button>
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Text onClick={onClose}>CANCEL</Text>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
