import { VStack } from '@chakra-ui/react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { DailyData, daysOfWeek } from '../Tabs/WaterTab'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    scales: {
        x: {
            ticks: {
                color: 'white',
            },
            grid: {
                color: 'rgba(255, 255, 255, 0.2)', // Fainter color for grid lines
                borderDash: [5, 5], // Dashed grid lines
            },
        },
        y: {
            ticks: {
                color: 'white',
                stepSize: 2000,
            },
            grid: {
                color: 'rgba(255, 255, 255, 0.2)',
                borderDash: [5, 5],
            },
            beginAtZero: true,
        },
    },
    elements: {
        line: {
            fill: 'start', // Fill the area under the line
        },
    },
}


export function LineChart () {
    const [waterDrankByDay, setWaterDrankByDay] = useState<number[]>([])

    const data = {
        labels : daysOfWeek,
        datasets: [
            {
                label: 'Dataset 1',
                data: waterDrankByDay,
                borderColor: '#F6F9FC',
                backgroundColor: '#EEEEEE',
            },
        ],
    }

    useEffect(() => {
        const waterDrankArray: number[] = []
        daysOfWeek.forEach((day) => {
            const dailyDataString = localStorage.getItem(day)
            if (dailyDataString) {
                const dailyData: DailyData = JSON.parse(dailyDataString)
                waterDrankArray.push(dailyData.waterDrank)
            } else {
                waterDrankArray.push(0)
            }
        })
        setWaterDrankByDay(waterDrankArray)
    }, [])
    return (
        <VStack
            height={{ base: '30vh', md: '40vh' }}
            bg="secondary.400"
            borderRadius="14px"
            p={{ base: 4, md: 10 }}
            gap={5}
        >
            <Line options={options} data={data} />
        </VStack>
    )
}
