/* eslint-disable */
// @ts-nocheck

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { routes } from './routes'
import PrivateWrapper from './ProtectedRoute'
import ScrollToTop from '../hooks/useScrollToTop'

export default function Router() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {routes.map(({ path, component, ...rest }) => (
                    <Route
                        path={path}
                        key={path}
                        element={
                            <PrivateWrapper {...rest}>
                                {component}
                            </PrivateWrapper>
                        }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    )
}
