import React, { useEffect, useState } from 'react'
import {
    Box,
    Center,
    Circle,
    Flex,
    Icon,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react'
import { FaBriefcaseMedical } from 'react-icons/fa'

import DashboardLayout from '../../layouts/DashboardLayout'
import BenefitsList from './_components/BenefitsList'
import { useSelector } from '../../hooks/useSelector'
// import { getBenefitsGraph } from '../../services/benefits.service'
import { PieChart } from '../../components/ui/Charts/PieChart'
import { GraphData } from '../../types/graphData'
import { getBenefitsGraph } from '../../services/benefits.service'
import { useQuery } from '@tanstack/react-query'
import { Bars } from 'react-loader-spinner'
import { NO_HEALTH_TIP_IMAGE } from '../../utils'

export function Benefits() {
    const user = useSelector((state) => state.auth.userData)
    const memberNumber = user.membernumber

    const [chartData, setChartData] = useState([
        {
            name: 'IN-PATIENT',
            amt: 0,
            color: '#683191',
            legendFontColor: '#7F7F7F',
            legendFontSize: 12,
            per: 0,
        },
        {
            name: 'OUT-PATIENT',
            amt: 0,
            color: '#F5A24D',
            legendFontColor: '#7F7F7F',
            legendFontSize: 12,
            per: 0,
        },
        {
            name: 'UNUSED',
            amt: 0,
            color: '#F3534A',
            legendFontColor: '#7F7F7F',
            legendFontSize: 12,
            per: 0,
        },
    ])

    console.log('memberNumber', memberNumber)

    // Using React Query
    const { data, isLoading, isError, isFetching } = useQuery({
        queryKey: ['benefitsGraph', memberNumber],
        queryFn: () => getBenefitsGraph(memberNumber),
    })
    useEffect(() => {
        if (data) {
            const graphData: GraphData = data
            // Claims
            const inClaims = graphData?.in_data
                ? Number(graphData.in_data.total_claims)
                : 0
            const outClaims = graphData?.out_data
                ? Number(graphData.out_data.total_claims)
                : 0
            // Benefits
            const inAmt = graphData?.in_data ? Number(graphData.in_data.amt) : 0
            const outAmt = graphData?.out_data
                ? Number(graphData.out_data.amt)
                : 0
            // Percentages
            const inPer = graphData?.in_data
                ? Number(graphData.in_data.per_usage)
                : 0
            const outPer = graphData?.out_data
                ? Number(graphData.out_data.per_usage)
                : 0
            // Calculations
            const totalBenefit = inAmt + outAmt
            const totalHistory = inClaims + outClaims
            const diff = totalBenefit - totalHistory
            const unusedPer = 100 - (inPer + outPer)

            setChartData([
                {
                    name: 'IN-PATIENT',
                    amt: inClaims,
                    color: '#683191',
                    legendFontColor: '#7F7F7F',
                    legendFontSize: 12,
                    per: inPer,
                },
                {
                    name: 'OUT-PATIENT',
                    amt: outClaims,
                    color: '#F5A24D',
                    legendFontColor: '#7F7F7F',
                    legendFontSize: 12,
                    per: outPer,
                },
                {
                    name: 'UNUSED',
                    amt: parseFloat(diff.toFixed(2)),
                    color: '#F3534A',
                    legendFontColor: '#7F7F7F',
                    legendFontSize: 12,
                    per: unusedPer,
                },
            ])
        }
    }, [data])

    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Benefits"
        >
            <Box bg="white" mt={{ base: -3, md: '-4vh' }}>
                <VStack pt={6} color="purple.700" fontWeight="700" spacing={-1}>
                    <Icon as={FaBriefcaseMedical} boxSize="4rem" mb={5} />
                    <Text fontSize="20px">{user.planname.toUpperCase()}</Text>
                    <Text fontSize="13px" color="gray.300">
                        You are on the {user.planname.toUpperCase()} plan
                    </Text>
                </VStack>

                <Flex align="center" justify="space-between" my={6} px={3}>
                    {(isLoading || isFetching) && !data ? (
                        <Center
                            height={{ base: '35vh', md: '50vh' }}
                            w="full"
                            flexDir="column"
                        >
                            <Bars
                                height="10vh"
                                width="10vw"
                                color="#F5A24D"
                                visible={true}
                            />
                            <Text
                                mt={4}
                                fontSize={{ base: 'x-small', md: 'large' }}
                                color="gray.200"
                                fontWeight="600"
                            >
                                Plotting Graph...
                            </Text>
                        </Center>
                    ) : isError ? (
                        <Center
                            height={{ base: '35vh', md: '50vh' }}
                            w="full"
                            flexDir="column"
                        >
                            <Image src={NO_HEALTH_TIP_IMAGE} boxSize="10vh" />
                            <Text
                                mt={4}
                                fontSize={{ base: 'x-small', md: 'large' }}
                                color="gray.200"
                                fontWeight="600"
                            >
                                No Graph Data
                            </Text>
                        </Center>
                    ) : (
                        <PieChart chartData={chartData} />
                    )}

                    <VStack align="start" spacing={12}>
                        {chartData.map((item, index) => (
                            <Flex
                                align="center"
                                justify="start"
                                key={item.name + index}
                            >
                                <Circle size="15px" bg={item.color} mr={3} />
                                <Text
                                    fontSize={{ base: 'x-small', md: 'medium' }}
                                    fontWeight="600"
                                    color="gray.200"
                                >
                                    {item.amt} {item.name}
                                </Text>
                            </Flex>
                        ))}
                    </VStack>
                </Flex>
                <>
                    {isFetching && data && (
                        <Center h="5vh" w="full" flexDir="row">
                            <Bars
                                height="5vh"
                                width="5vw"
                                color="#F5A24D"
                                visible={true}
                            />
                            <Text
                                ml={1}
                                fontSize={{ base: 'x-small', md: 'md' }}
                                color="gray.200"
                                fontWeight="600"
                            >
                                Fetching updates...
                            </Text>
                        </Center>
                    )}
                </>
                <VStack
                    mt={8}
                    h={{ base: '35vh', md: '54vh' }}
                    overflow="scroll"
                >
                    <BenefitsList />
                </VStack>
            </Box>
        </DashboardLayout>
    )
}
