import React from 'react'
import { Box, Text, Center, Avatar, Flex } from '@chakra-ui/react'
// import CardBackground from '../../assets/background/ecard/gbg.png'
import DashboardLayout from '../../layouts/DashboardLayout'
import CardDetails from './_components/CardDetails'
import { getCardImage } from '../../utils/cardBackground'
import { useSelector } from '../../hooks/useSelector'
import { decodeImage } from '../../utils'

export function Ecard() {
    const user = useSelector((state) => state.auth.userData)
    return (
        <DashboardLayout paddingX={false} showNavbar={false} pageTitle="ECard">
            <Flex w="100vw" h="100vh" justify="center" align="center">
                <Center
                    borderRadius="lg"
                    bgImage={getCardImage(user.plans, user.employer)}
                    bgSize="cover"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    w="60vh"
                    h={{ base: '50vh', md: '70vh' }}
                    transform="rotate(90deg)"
                    mx="auto"
                    position="absolute"
                    top="25vh"
                    left="-10vw"
                >
                    <Box
                        flexDirection="row"
                        alignItems="flex-end"
                        justifyContent="center"
                        pb={20}
                        pl={30}
                    >
                        <Box
                            flex={0.3}
                            justifyContent="center"
                            pb={10}
                            position="absolute"
                            bottom={0}
                            left={6}
                        >
                            <Avatar
                                // name={user.firstname + " " + user.lastname}
                                src={
                                    `${decodeImage(`${user.imgperspic}`)}` || ''
                                }
                                w={130}
                                h={130}
                                objectFit="cover"
                            />
                        </Box>

                        <Box flex={0.8} mt="25vw" ml="-60px">
                            <Text
                                transform="rotate(90deg)"
                                color="white"
                                fontSize="22px"
                                fontWeight="600"
                            >
                                {user.planname}
                            </Text>
                            <CardDetails />
                        </Box>
                    </Box>
                </Center>
            </Flex>
        </DashboardLayout>
    )
}
