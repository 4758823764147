import { Box, Flex, Avatar, Text } from '@chakra-ui/react'
import { useSelector } from '../../../hooks/useSelector'
import { decodeImage } from '../../../utils'

export const UserInfo = () => {
    const user = useSelector((state) => state.auth.userData)

    return (
        <Flex
            h="20"
            alignItems="center"
            justifyContent="space-evenly"
            position={'static'}
        >
            <Avatar
                size="lg"
                src={`${decodeImage(`${user.imgperspic}`)}` || ''}
            />

            <Box gap={2}>
                <Text
                    textTransform={'capitalize'}
                    fontSize="14px"
                    marginTop={1}
                    fontWeight="bold"
                >
                    {user.firstname} {user.lastname}
                </Text>
                <Text color="gray.500" fontSize="14px" lineHeight="14px">
                    {user.membernumber}
                </Text>
            </Box>
        </Flex>
    )
}
