import { VStack, Text } from '@chakra-ui/react';

interface UserBodyStackProps {
  label: string;
  value: string;
}

const UserBodyStack = ({ label, value } : UserBodyStackProps) => (
  <VStack
    flex="1"
    align="center"
    justify="center"
    borderColor="#dddddd"
    borderWidth="1px"
    py="20px"
  >
    <Text p={3}>{label}</Text>
    <Text>{value}</Text>
  </VStack>
);

export default UserBodyStack;
