import React from 'react';
import { Button, Image as ChakraImage, Flex } from '@chakra-ui/react';

interface VolumeButtonProps {
  volume: number;
  imageSrc: string;
  onSelect: (volume: number) => void;
}

const VolumeButton: React.FC<VolumeButtonProps> = ({ volume, imageSrc, onSelect }) => {
  return (
    <Flex as={Button} onClick={() => onSelect(volume)} variant="ghost"flexDirection="column" gap={3} fontSize="14px">
      <ChakraImage src={imageSrc} alt={`Volume ${volume}`} boxSize="25px" />
      {volume}ml
    </Flex>
  );
};

export default VolumeButton;
