import { Box, Flex, Input, VStack } from '@chakra-ui/react'
import DashboardLayout from '../../layouts/DashboardLayout'
import RegionList from './_components/RegionList'
import HospitalsList from './_components/HospitalList'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import {
    setHospitalLoading,
    setItems,
    setSearch,
} from '../../store/hospital.slice'
import { searchHospital } from '../../services/hospital.service'
import { ServiceEndpoints } from '../../utils'
import { axios } from '../../services/apiConfig'

export const getAllHospitals = async () => {
    try {
      const response = await axios.get(ServiceEndpoints.ALLHOSPITALS);
      return response.data;
    } catch (error) {
      console.error('Error fetching hospitals:', error);
      throw error;
    }
  };

export function Hospitals() {
    const [searchTerm, setSearchTermLocal] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setSearchTermLocal(newValue)

        // Dispatch the searchTerm to Redux store after a delay
        // Adjust the delay time (e.g., 1000 milliseconds = 1 second) based on your preference
        setTimeout(() => {
            dispatch(setSearch(newValue))
        }, 1000)
    }

    const handleSearch = async () => {
        setLoading(true)
        dispatch(setHospitalLoading(true))

        try {
            // Perform the search using searchTerm
            const searchData = await searchHospital(searchTerm)
            const searchItems = searchData.data
            // console.log(searchItems)
            dispatch(setItems(searchItems))

            // Handle the search results as needed
            setLoading(false)
            dispatch(setHospitalLoading(loading))
        } catch (error) {
            console.error('Error searching hospitals:', error)
            setLoading(false)
            dispatch(setHospitalLoading(loading))
        }
    }

    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Hospitals"
        >
            <VStack align="stretch" flex={1} px={4}>
                <Input
                    variant="secondaryInput"
                    placeholder="Search by name, location"
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSearch()
                        }
                    }}
                />
                <Flex
                    mt={5}
                    pb={2}
                    borderBottom="0.5px solid #DADCE0"
                    justify="space-between"
                    align="center"
                >
                    <RegionList />
                </Flex>
                <Box>
                    <HospitalsList />
                </Box>
            </VStack>
        </DashboardLayout>
    )
}
