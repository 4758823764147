import { TextProps } from '@chakra-ui/react'
// import { Hospital } from './_components/HospitalList';

export const regionsInGhana = [
    { name: 'All', capital: '' },
    { name: 'Ashanti', capital: 'Kumasi' },
    { name: 'Brong Ahafo', capital: 'Sunyani' },
    { name: 'Central', capital: 'Cape Coast' },
    { name: 'Eastern', capital: 'Koforidua' },
    { name: 'Greater Accra', capital: 'Accra' },
    { name: 'Northern', capital: 'Tamale' },
    { name: 'Upper East', capital: 'Bolgatanga' },
    { name: 'Upper West', capital: 'Wa' },
    { name: 'Volta', capital: 'Ho' },
    { name: 'Western', capital: 'Sekondi-Takoradi' },
]

export const commonTextStyle = (
    fontWeight: TextProps['fontWeight'],
    fontSize: TextProps['fontSize'],
    color: TextProps['color']
): TextProps => ({
    fontWeight,
    fontSize,
    color,
})

