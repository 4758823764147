import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { VStack } from '@chakra-ui/react'

ChartJS.register(ArcElement, Tooltip, Legend)

interface ChartDataProps {
    chartData: {
        name: string
        amt: number
        color: string
        legendFontColor: string
        legendFontSize: number
        per: number
    }[]
}

export const PieChart = ({ chartData }: ChartDataProps) => {
    const labels = chartData.map((item) => item.name)
    const graphColors = chartData.map((item) => item.color)
    const per = chartData.map((item) => item.per)

    const data = {
        labels,

        datasets: [
            {
                label: `Percentage of Values`,
                data: per,

                backgroundColor: graphColors,
                borderWidth: 0,
                borderColor: 'transparent',
            },
        ],
    }

    const options = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    }

    return (
        <VStack
            height={{ base: '35vh', md: '50vh' }}
            w="full"
            borderRadius="14px"
            p={{ base: 1, md: 10 }}
        >
            <Pie data={data} options={options} />;
        </VStack>
    )
}
