import React, { useState } from 'react'
import {
    Center,
    HStack,
    PinInput,
    PinInputField,
    Box,
    Heading,
    Text,
    CardBody,
    CardHeader,
    Button,
    useToast,
} from '@chakra-ui/react'
import { AuthCard } from '../../components/ui'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../utils'
import { useDispatch } from 'react-redux'
import {
    setIsAuthenticated,
    setTokens,
    setUser,
    setUserData,
} from '../../store/auth.slice'
import AuthLayout from '../../layouts/AuthLayout'
import { getUser, loginWithOTP } from '../../services/auth.service'
import { UserData } from '../../types'
import { AxiosResponse } from 'axios'
import { validateMembershipId, validateOTP } from '../../utils/validation'
import { useSelector } from '../../hooks/useSelector'

export function OTP() {
    const [enteredOTP, setEnteredOTP] = useState<string[]>(Array(6).fill(''))
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const user = useSelector((state) => state.auth.user)

    const memberId = user.memberId

    console.log(user)
    const handleOTPChange = (value: string, index: number) => {
        const newEnteredOTP = [...enteredOTP]
        newEnteredOTP[index] = value
        setEnteredOTP(newEnteredOTP)
    }

    const handleOTPVerification = async () => {
        // Validation checks
        const formattedOTP = enteredOTP.join('')
        // Validation checks
        if (!validateMembershipId(memberId) || !validateOTP(formattedOTP)) {
            return
        }

        setLoading(true)
        try {
            const objData = await loginWithOTP(memberId, formattedOTP)
            const responseData = objData

            const access = responseData.access
            const refresh = responseData.refresh

            dispatch(setTokens({ accessToken: access, refreshToken: refresh }))
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
            localStorage.setItem('isFirstTime', 'false')
            dispatch(setIsAuthenticated(true))

            const userDataResponse: AxiosResponse<UserData> = await getUser()
            const userData: UserData = userDataResponse.data
            dispatch(
                setUser({
                    firstName: userData.firstname,
                    lastName: userData.lastname,
                    email: userData.email || '',
                    phoneNumber: userData.mobilephone,
                    memberId: userData.membernumber,
                })
            )
            dispatch(setUserData(userData))
            console.log(userData)
            setLoading(false)
            navigate(AppRoutes.HOME)
        } catch (error) {
            console.error('Authentication failed:', error)
            toast({
                title: 'Authentication Failed',
                description: 'Try again',
                status: 'warning',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <AuthLayout>
            <AuthCard>
                <Box h="80%">
                    <CardHeader textAlign="center">
                        <Heading
                            fontSize="22px"
                            fontWeight="400"
                            textAlign="center"
                        >
                            Verification
                        </Heading>
                        <Text
                            mt="8px"
                            textAlign="center"
                            color="#D0D0D0"
                            fontSize="15px"
                        >
                            Enter the 6 digit OTP that was sent to{' '}
                            <Text color="#683191" fontSize="14px">
                                {user.phoneNumber}{' '}
                            </Text>
                        </Text>
                    </CardHeader>
                    <CardBody>
                        <Box
                            position="absolute"
                            left={-6}
                            top={{ base: '56%', md: '45%' }}
                        >
                            <Center
                                flexDir="column"
                                justifyContent="center"
                                alignContent="center"
                                h="100%"
                            >
                                <HStack spacing={4} mb="22px">
                                    <PinInput
                                        otp
                                        size={{ base: 'md', md: 'lg' }}
                                    >
                                        {[...Array(6)].map((_, index) => (
                                            <PinInputField
                                                key={index}
                                                value={enteredOTP[index]}
                                                onChange={(event) => {
                                                    handleOTPChange(
                                                        event.target.value,
                                                        index
                                                    )
                                                }}
                                            />
                                        ))}
                                    </PinInput>
                                </HStack>
                                <Button
                                    onClick={handleOTPVerification}
                                    variant="primary"
                                    isLoading={loading}
                                >
                                    CONFIRM
                                </Button>
                            </Center>
                        </Box>
                    </CardBody>
                </Box>
            </AuthCard>
        </AuthLayout>
    )
}
