import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import {
    CAPSULE_IMAGE,
    DROP_IMAGE,
    INJECTION_IMAGE,
    TABLET_IMAGE,
} from '../../../utils'
import { Medication } from '../../../types'

interface CurrentItemProps {
    medicine: Medication
    onClick: () => void
}

export const CurrentItem = ({ medicine, onClick }: CurrentItemProps) => {
    const [image, setImage] = useState<string>()
    useEffect(() => {
        switch (medicine.type) {
            case 'Drop':
                setImage(DROP_IMAGE)
                break
            case 'Tablet':
                setImage(TABLET_IMAGE)
                break
            case 'Injection':
                setImage(INJECTION_IMAGE)
                break
            case 'Capsule':
                setImage(CAPSULE_IMAGE)
                break
            default:
                setImage(CAPSULE_IMAGE)
                break
        }
        return () => {}
    }, [])
    return (
        <Flex
            w="100%"
            borderRadius={6}
            bg="white"
            gap={6}
            justifyContent="start"
            alignItems="center"
            py={3}
            px={6}
            onClick={onClick}
        >
            <Image src={image} boxSize="40px" />
            <Box>
                <Text fontWeight={600} fontSize="14px">
                    {medicine.name}
                </Text>
                <Text>Dosage: {medicine.dosage}</Text>
            </Box>
        </Flex>
    )
}
