import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import {
    Box,
    Button,
    Divider,
    Flex,
    Input,
    Switch,
    Text,
} from '@chakra-ui/react'
import moment from 'moment'
import { Medication } from '../../types'
import { AppRoutes, toast } from '../../utils'
import { useDispatch } from 'react-redux'
import { addMedication } from '../../store/medication.slice'
import { useNavigate } from 'react-router'
import { useURIQuery } from '../../hooks/useQuery'

const medicineTypes = ['Tablet', 'Capsule', 'Drop', 'Injection']

export const AddMedicine = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = useURIQuery()
    const name = query.get('name')
    const [formData, setFormData] = useState<Medication>({
        dosage: 1,
        interval: 1,
        //duration: "everyday",
        alarm: true,
        type: 'Tablet',
        name: '',
        start_time: new Date(moment().format('ddd MMM DD YYYY HH:mm:ss ZZ')), //time user started taking medicine
        reminders: [
            {
                time: new Date(moment().format('ddd MMM DD YYYY HH:mm:ss ZZ')),
                id: '',
            },
        ],
    })

    useEffect(() => {
        if (name) {
            handleOnChange(name)
        }
    }, [name])

    const handleOnChange = (name: string) => {
        setFormData({ ...formData, name })
    }

    const increaseDosage = () => {
        if (formData.dosage === 10) {
            toast({
                title: 'Oops',
                description: 'Maximum dosage reached',
                status: 'error',
            })
            console.log('Maximum dosage reached')
        } else {
            const dosage = formData.dosage + 1
            setFormData({ ...formData, dosage })
        }
    }

    const decreaseDosage = () => {
        if (formData.dosage === 1) {
            toast({
                title: 'Oops',
                description: 'Mininum dosage reached',
                status: 'error',
            })
            console.log('Minimum dosage reached')
        } else {
            const dosage = formData.dosage - 1
            setFormData({ ...formData, dosage })
        }
    }

    const getReminderIds = async (
        reminders: {
            time: Date
            id: string
        }[]
    ) => {
        const allAsyncResults = []
        for (const reminder of reminders) {
            console.log('we are here')
            // const minutes = moment(reminder.time).format('mm')
            // const hours = moment(reminder.time).format('HH')

            // const id = await drugReminderNotification(formData.name, Number(hours), Number(minutes))
            // allAsyncResults.push({time: reminder.time, id})
            allAsyncResults.push({ time: reminder.time, id: reminder.id })
        }
        return allAsyncResults
    }

    const addMedicineToMedication = async () => {
        if (formData.name) {
            let reminders = formData.reminders

            if (formData.alarm) {
                reminders = await getReminderIds(reminders)
            }

            console.log(reminders)

            const objData = { ...formData, reminders }
            dispatch(addMedication(objData))

            toast({
                title: 'Success',
                description: `${formData.name} has been added to your medication`,
                status: 'success',
            })
            navigate(AppRoutes.MEDICATIONS)
            console.log(objData)
        } else {
            toast({
                title: 'Sorry',
                description: 'Drug name required',
                status: 'error',
            })
        }
    }

    const addReminder = () => {
        setFormData({
            ...formData,
            reminders: [
                ...formData.reminders,
                {
                    time: new Date(
                        moment().format('ddd MMM DD YYYY HH:mm:ss ZZ')
                    ),
                    id: '',
                },
            ],
        })
    }

    const deleteReminder = (key: number) => {
        let reminders = formData.reminders
        reminders = reminders.filter((reminder, index) => {
            if (key != index) {
                return reminder
            }
        })

        setFormData({ ...formData, reminders })
    }

    return (
        <DashboardLayout
            showNavbar={false}
            pageTitle="Add medicine"
            showBottomNav={false}
        >
            <Flex flexDir="column" gap={8}>
                <Input
                    placeholder="Medicine name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(event) => handleOnChange(event.target.value)}
                />
                <Flex
                    flexDir="column"
                    boxShadow="sm"
                    bg="white"
                    p={6}
                    borderRadius={20}
                    dropShadow="initial"
                    gap={3}
                >
                    <Flex gap={2}>
                        {medicineTypes.map((type, index) => {
                            return (
                                <Box key={index}>
                                    <Button
                                        variant={
                                            formData.type == type
                                                ? 'secondary'
                                                : 'outline'
                                        }
                                        px={2}
                                        py={4}
                                        size="xs"
                                        borderRadius={6}
                                        onClick={() => {
                                            setFormData({ ...formData, type })
                                        }}
                                    >
                                        <Text
                                            fontWeight="normal"
                                            fontSize="10px"
                                        >
                                            {type}
                                        </Text>
                                    </Button>
                                </Box>
                            )
                        })}
                    </Flex>
                    <Text>Reminder times</Text>
                    <Divider />
                    <Flex justifyContent="space-between">
                        <Text>Dosage</Text>
                        <Flex {...styles.dosage_row}>
                            <Flex
                                {...styles.dosage_button}
                                onClick={() => decreaseDosage()}
                            >
                                <Text>-</Text>
                            </Flex>
                            <Text>{formData.dosage}</Text>
                            <Flex
                                {...styles.dosage_button}
                                onClick={() => increaseDosage()}
                            >
                                <Text>+</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    flexDir="column"
                    boxShadow="sm"
                    bg="white"
                    p={6}
                    borderRadius={20}
                    dropShadow="initial"
                    gap={1}
                >
                    <Flex justifyContent="space-between">
                        <Text>Alarm</Text>
                        <Switch
                            isChecked={formData.alarm}
                            color="secondary.400"
                            colorScheme="orange"
                            onChange={() => {
                                setFormData({
                                    ...formData,
                                    alarm: !formData.alarm,
                                })
                            }}
                        />
                    </Flex>
                    <Divider />
                    {formData.alarm ? (
                        <>
                            <Text>Set Reminders</Text>

                            <Divider mb={2} />
                            <Flex flexDir="column" gap={4}>
                                {formData.reminders.map((reminder, index) => {
                                    return (
                                        <Flex
                                            justifyContent="space-between"
                                            key={index}
                                            aria-label={
                                                'Reminder' + (index + 1)
                                            }
                                        >
                                            <Text>Reminder {index + 1}</Text>
                                            <Flex align="center" gap={1}>
                                                <Text>
                                                    {moment(
                                                        reminder.time
                                                    ).format('HH:mm A')}
                                                </Text>
                                                <Flex
                                                    {...styles.dosage_button}
                                                    bg="red.500"
                                                    mr={4}
                                                    onClick={() =>
                                                        deleteReminder(index)
                                                    }
                                                >
                                                    <Text>-</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                })}
                                <Flex justifyContent="end">
                                    <Flex
                                        {...styles.dosage_button}
                                        onClick={() => addReminder()}
                                    >
                                        <Text>+</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    ) : null}
                </Flex>
                <Button variant="secondary" maxW="200px" alignSelf="center">
                    <Text
                        fontWeight="normal"
                        onClick={() => addMedicineToMedication()}
                    >
                        ADD MEDICINE
                    </Text>
                </Button>
                {/* TODO::implement notifications */}
            </Flex>
        </DashboardLayout>
    )
}

const styles = {
    dosage_row: {
        borderWidth: 1,
        borderColor: '#EEEEEE',
        borderRadius: 12,
        justifyContent: 'space-between',
        align: 'center',
        py: 0.5,
        px: 1,
        gap: 4,
    },
    dosage_button: {
        h: '20px',
        w: '20px',
        borderRadius: 10,
        bg: 'secondary.400',
        color: 'white',
        justifyContent: 'center',
        align: 'center',
    },
}
