export class AppRoutes {
    static LOGIN = '/login'
    static OTP = '/otp'
    static HOME = '/'
    static PROFILE = '/profile'
    static HISTORY = '/history'
    static HISTORY_DETAILS = '/history_details'
    static CLAIMS = '/claims'
    static DEPENDANTS = '/dependants'
    static DEPENDANT_HISTORY = '/dependants_history'
    static EXCLUSIONS = '/exclusions'
    static BENEFITS = '/benefits'
    static HEALTH_QUIZ = '/health_quiz'
    static SUPPORT = '/support'
    static FAQ = '/faq'
    static CONTACT = '/contact'
    static E_CARD = '/e_card'
    static HOSPITALS = '/hospitals'
    static MEDICATIONS = '/medications'
    static ADD_MEDICATION = '/add_medication'
    static VIEW_MEDICATION = '/view_medication'
    static CHAT = '/chat'
    static CHAT_BOT = '/chatbot'
    static FORUM = '/forum'
    static WATER_REMINDER = '/water_reminder'
    static MEDICATION_REQUEST = '/medication-request'
    // static TERMSOFUSAGE = '/terms'
    // static PRIVACY = '/privacy'
}

export class ServiceEndpoints {
    //auth
    static LOGIN = '/user/login/'
    static GET_OTP = '/user/otp/'
    static REFRESH = '/user/token/refresh/'
    static USER = '/user/getinfo/'
    static USER_INFO = 'user/getinfo/'
    static LOGIN_WITH_OTP = '/user/otp/login/'
    static FAQS = '/faq/'
    static GET_USER_HISTORY = 'claims/list/'
    static SEARCH_HISTORY = `claims/list/hospital/`
    static GET_HISTORY_DETAILS = 'claims/detail/'
    static GET_DEPENDANTS = 'user/dependants/'

    //home
    static HEALTH_TIPS = '/user/healthtip/'

    // hospitals
    static ALLHOSPITALS = '/hospitals/'
    static HOSPITALBYSEARCH = '/hospital/find/'
    static HOSPITALBYREGION = '/hospital/find/region/'

    // benefits
    static GETALLBENEFITS = '/capsbenefit/'

    // claims
    static POST_CLAIM_REFUND = '/user/claimrefund/'
    static GETGRAPHDATA = '/claims/usagelist/'
}

export const APP_NAME = process.env.APP_NAME || 'Acacia'
