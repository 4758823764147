import { axios } from './apiConfig'
import { ServiceEndpoints } from '../utils'

export const getAllHospitals = async () => {
    return axios
        .get(ServiceEndpoints.ALLHOSPITALS)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getNextHospitals = async (url: string) => {
    try {
        const response = await axios.get(url)
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const searchHospital = async (searchKey: string) => {
    try {
        const response = await axios.post(
            `${ServiceEndpoints.HOSPITALBYSEARCH}`,
            { hsp: searchKey }
        )
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getHospitalsByRegion = async (region: string) => {
    try {
        const response = await axios.post(ServiceEndpoints.HOSPITALBYREGION, {
            region: region,
        })
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getNextHospitalsByRegion = async (url: string, region: string) => {
    try {
        const response = await axios.post(url, {region})
        return Promise.resolve(response)
    } catch (error) {
        return Promise.reject(error)
    }
}

