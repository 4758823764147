import React from "react";
import { Box, Button, Center, Flex, Icon, Image } from "@chakra-ui/react";

import DashboardLayout from "../../layouts/DashboardLayout";
import ContactImage from "../../assets/background/contact.png";
import { Logo } from "../../components/ui";
import { contacts, socialMediaIcons } from "./constants";
import ContactItem from "./_components/ContactItem";

export function Contact() {
  const openWebsite = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <DashboardLayout paddingX={false} showNavbar={false} pageTitle="Contact">
      <Box paddingX={{ base: 4, md: 12 }} backgroundColor="bg.100">
        <Center paddingBottom={30} marginTop={-35}>
          <Image src={ContactImage} />
        </Center>

        {contacts.map((item,index) => (
          <ContactItem
            description={item.description}
            icon={item.icon}
            url={item.url || ""}
            key={item.name + index}
            textStyle={item.textStyle}
          />
        ))}

        <Box paddingTop={10} backgroundColor="bg.100">
          <Flex direction="row" justifyContent="center">
            {socialMediaIcons.map((item, index) => (
              <Button
              key={item.name + index}
                variant="socialMediaItems"
                _hover={{ bg: "none" }}
                onClick={() => openWebsite(item.url)}
              >
                <Icon as={item.icon} boxSize={8} color={item.color} />
              </Button>
            ))}
          </Flex>
        </Box>

        <Center paddingY={10}>
          <Logo />
        </Center>
      </Box>
    </DashboardLayout>
  );
}
