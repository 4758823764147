import {
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { MAIN_BG_IMAGE } from '../../utils'
import WaterTab from '../../components/ui/Tabs/WaterTab'
import SettingsTab from '../../components/ui/Tabs/SettingsTab'
import HistoryTab from '../../components/ui/Tabs/HistoryTab'

const tabNames = [
    {
        name: 'Water',
    },
    {
        name: 'History',
    },
    {
        name: 'Settings',
    },
]

export function WaterReminder() {
    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            showBottomNav={false}
            pageTitle="Drink Water Reminder"
        >
            <Tabs
                position="fixed"
                variant="unstyled"
                align="center"
                isFitted
                bgImage={MAIN_BG_IMAGE}
                h="100vh"
                w="100vw"
                color="white"
            >
                <TabList bg="bg.200" mt={{ base: '-15px', md: '-47px' }} py={2}>
                    {tabNames.map((tab, index) => (
                        <Tab
                            fontSize="12px"
                            textTransform="uppercase"
                            key={tab.name + index}
                        >
                            {tab.name}
                        </Tab>
                    ))}
                </TabList>
                <TabIndicator
                    mt="-1.5px"
                    height="3px"
                    bg="secondary.400"
                    borderRadius="1px"
                />
                <TabPanels>
                    <TabPanel>
                        <WaterTab defaultVolume={400} />
                    </TabPanel>
                    <TabPanel>
                        <HistoryTab />
                    </TabPanel>
                    <TabPanel>
                        <SettingsTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </DashboardLayout>
    )
}
