import React, { useEffect, useState, useRef } from 'react'
import { VStack, Spinner, Center } from '@chakra-ui/react'
import DashboardLayout from '../../layouts/DashboardLayout'
import FAQAccordion from './_components/FAQAccordion'
import { axios } from '../../services/apiConfig'
import { ServiceEndpoints, toast } from '../../utils'

interface FAQ {
    id: number
    question: string
    answer: string
}

export function FAQPage() {
    const [loading, setLoading] = useState(true)
    const [faqs, setFaqs] = useState<FAQ[]>([])
    const [nextPageUrl, setNextPageUrl] = useState<string | null>(null)
    // const [allFaqsLoaded, setAllFaqsLoaded] = useState<boolean>(false)
    const sentinelRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const fetchFAQs = async () => {
            try {
                const faqsResonse = await axios.get(ServiceEndpoints.FAQS)
                const faqsData = faqsResonse.data

                setFaqs(faqsData.results)
                setNextPageUrl(faqsData.links.next)
                setLoading(false)
            } catch (error) {
                console.error('Error:', error)
                toast({
                    description: "Couldn't get FAQs!",
                    status: 'error',
                })
            }
        }

        fetchFAQs()
    }, [])

    const loadMoreFAQs = async () => {
        if (!nextPageUrl) {
            return // No more FAQs to load
        }

        try {
            const nextPageResonse = await axios.get(nextPageUrl)
            const nextPageData = nextPageResonse.data

            setFaqs([...faqs, ...nextPageData.results])
            setNextPageUrl(nextPageData.links.next)
        } catch (error) {
            console.error('Error loading more FAQs:', error)
            toast({
                description: "Couldn't load more FAQs!",
                status: 'error',
            })
        }
    }

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting) {
            loadMoreFAQs()
        }
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        }

        const observer = new IntersectionObserver(handleIntersect, options)
        if (sentinelRef.current) {
            observer.observe(sentinelRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [faqs]) // Reobserve when FAQs change

    return (
        <DashboardLayout paddingX={false} showNavbar={false} pageTitle="FAQ">
            <VStack spacing={4} mt={{ base: -4, md: -12 }}>
                {loading && (
                    <Center h="80vh">
                        <Spinner size="lg" color="purple.700" />
                    </Center>
                )}
                <FAQAccordion faqs={faqs} />
                <div ref={sentinelRef}></div>
            </VStack>
        </DashboardLayout>
    )
}
