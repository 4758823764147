import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import theme from './components/theme/theme'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import Router from './routes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
        },
    },
})

export const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                <Router />
                </QueryClientProvider>
            </ChakraProvider>
        </PersistGate>
    </Provider>
)
