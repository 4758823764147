import React from 'react'
import {
    Button,
    Card,
    Flex,
    HStack,
    Icon,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import LocationModal from './LocationModal'
import { FaLocationPin, FaPhone } from 'react-icons/fa6'
import { Hospital } from '../../../types'
import { useDispatch } from 'react-redux'
import { setSelectedHospital } from '../../../store/hospital.slice'
import { useSelector } from '../../../hooks/useSelector'

interface HospitalCardProps {
    hospital: Hospital
}

export const HospitalCard = ({ hospital }: HospitalCardProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()

    const selectedHospital = useSelector(
        (state) => state.hospital.selectedHospital
    )

    const handlePhoneClick = () => {
        if (selectedHospital && selectedHospital.contact) {
            window.open(`tel:${selectedHospital.contact}`, '_blank')
        }
    }

    const handleMapClick = () => {
        onOpen()
    }

    const clickedHospital = () => {
        // console.log(hospital.name)
        dispatch(setSelectedHospital(hospital))
    }

    return (
        <Card
            borderBottom="0.5px solid #DADCE0"
            py={8}
            px={6}
            mb={3}
            variant="ghost"
            onClick={clickedHospital}
        
        >
            <Flex align="center" justify="space-between">
                <VStack align="start" w="60%" p={2} mr={4}>
                    <Text fontWeight="500" fontSize="16px" >
                        {hospital.name}
                    </Text>
                    <Text fontWeight="700" fontSize="14px">
                        {hospital.loc}
                    </Text>
                    <Text color="purple.700" >{hospital.spec}</Text>
                </VStack>

                <HStack>
                    <Button variant="modalIcons" onClick={handlePhoneClick}>
                        <Icon as={FaPhone} boxSize={4} color="purple.700" />
                    </Button>
                    <Button variant="modalIcons" onClick={handleMapClick}>
                        <Icon
                            as={FaLocationPin}
                            boxSize={4}
                            color="tertiary.100"
                        />
                    </Button>

                    <LocationModal
                        isOpen={isOpen}
                        onClose={onClose}
                        // hospitalInfo={selectedHospital}
                    />
                </HStack>
            </Flex>
        </Card>
    )
}
