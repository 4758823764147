import { Select, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { regionsInGhana } from '../constants'
import { setSearchRegion, setSelectedHospital } from '../../../store/hospital.slice'
// import { getHospitalsByRegion } from '../../../services/hospital.service'

const RegionList = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        // Dispatch the default selected region as "all" when the component mounts
        dispatch(setSearchRegion('All'))

    }, [dispatch])

    const handleRegionChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            const selectedRegion = event.target.value
            // Dispatch the selected region to the store
            dispatch(setSearchRegion(selectedRegion))
            dispatch(setSelectedHospital({}))            
        },
        [dispatch]
    )

    return (
        <Select
            icon={<MdArrowDropDown />}
            placeholder="Region"
            variant="flushed"
            _placeholder={{ color: 'gray.100' }}
            onChange={handleRegionChange}
        >
            {regionsInGhana.map((item, index) => (
                <option value={item.name} key={item.name + index}>
                    <Text color="black">{item.name}</Text>
                </option>
            ))}
        </Select>
    )
}

export default RegionList
