// hospital.slice.js
import { createSlice } from "@reduxjs/toolkit";
import { HospitalState } from "../types";

const initialState: HospitalState = {
    refreshing: false,
    loading: true,
    moreLoading: false,
    mounted: true,
    items: [],
    nextPage: "",
    search: "",
    total: null,
    size: null,
    activeKey: null,
    modalVisible: false,
    selectedHospital: {
        area: "",
        contact: "",
        hcode: "",
        hplan: "",
        loc: "",
        name: "",
        region: "",
        spec: ""
    },
    searchRegion: "",
    region: {
      latitude: 7.946527,
      longitude: -1.023194,
      latitudeDelta: 0.05,
      longitudeDelta: 0.05,
    },
    points: [],
  }

 const hospitalSlice = createSlice({
  name: "hospital",
  initialState,
  reducers: {
    setRefreshing: (state, action) => {
      state.refreshing = action.payload;
    },
    setHospitalLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMoreLoading: (state, action) => {
      state.moreLoading = action.payload;
    },
    setMounted: (state, action) => {
      state.mounted = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    },
    setModalVisible: (state, action) => {
      state.modalVisible = action.payload;
    },
    setSelectedHospital: (state, action) => {
      state.selectedHospital = action.payload;
    },
    setSearchRegion: (state, action) => {
      state.searchRegion = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setPoints: (state, action) => {
      state.points = action.payload;
    },
  },
});

export const {
  setRefreshing,
  setHospitalLoading,
  setMoreLoading,
  setMounted,
  setItems,
  setNextPage,
  setSearch,
  setTotal,
  setSize,
  setActiveKey,
  setModalVisible,
  setSelectedHospital,
  setSearchRegion,
  setRegion,
  setPoints,
} = hospitalSlice.actions;

export default hospitalSlice.reducer;
