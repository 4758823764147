import React from 'react'
import './WaterWave.css'

interface WaveProps {
    percentage: number
}

const Wave: React.FC<WaveProps> = ({ percentage }) => {
    const topPosition = `calc(-100% - ${percentage}%)`

    return (
        <div className="container">
            <div className="loader">
                <div
                    className="loader-before"
                    style={{ top: topPosition }}
                ></div>
                <div
                    className="loader-after"
                    style={{ top: topPosition }}
                ></div>
            </div>
        </div>
    )
}

export default Wave
