import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { API_DOMAIN } from '../../../constants/config'
import { Link } from 'react-router-dom'

interface HealthTipCardProps {
    title: string
    avatar: string
    name: string
    bgImg: string
    url: string
}

export const HealthTipCard = ({
    bgImg,
    title,
    avatar,
    name,
    url
}: HealthTipCardProps) => {
    return (
        <Link to={`/health_tip/${title}`} state={{url : url, title: title }}>
        <Flex
            flexDir={'column'}
            h={'250px'}
            w={'300px'}
            borderRadius={12}
            shadow={'md'}
            flexShrink={0}
            justifyContent={'space-between'}
            onClick={() => console.log(title)}
        >
            <Box
                h={'160px'}
                // bg={'#9DA3B4'}
                bg={`url(${API_DOMAIN}/static/${bgImg})`}
                backgroundSize="cover"
                backgroundPosition="center"
                borderTopEndRadius={12}
                borderTopLeftRadius={12}
            ></Box>
            <Flex
                flexDir={'column'}
                justifyContent={'space-around'}
                gap={4}
                px={3}
                py={4}
            >
                <Text isTruncated>{title}</Text>
                <Flex
                    flexDir={'row'}
                    gap={4}
                    justifyContent={'start'}
                    alignItems={'center'}
                >
                    <Image src={`${API_DOMAIN}/static${avatar}`} w={8} h={8} />

                    <Text>{name || 'Dr. Cacia'}</Text>
                </Flex>
            </Flex>
        </Flex>
        </Link>
    )
}
