import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Flex, Spinner } from '@chakra-ui/react'
import { HistoryItem } from './_components'
import { getDependantHistory, getNextHistory } from '../../services'
import { useSelector } from '../../hooks/useSelector'
import { HistoryLoader } from '../../components/ui'
import { getNextUrl } from '../../utils'
import { HistoryDetail } from '../../types'

export const DependantHistoryPage = () => {
    const memberId = useSelector((state) => state.auth.userData.membernumber)
    const [loading, setLoading] = useState(true)
    const [mounted, setMounted] = useState(true)
    const [moreLoading, setMoreLoading] = useState(false)
    const [items, setItems] = useState<HistoryDetail[]>([])
    const [nextPage, setNextPage] = useState<string>('')
    const sentinelRef = useRef<HTMLDivElement>(null)
    //   const [networkError, setNetworkError] = useState(false);

    useEffect(() => {
        setMounted(true)
        asnycGetUserHistory()
        return () => {
            setMounted(false)
            setLoading(false)
        }
    }, [])

    async function asnycGetUserHistory() {
        setLoading(true)
        try {
            const objData = await getDependantHistory(memberId)
            const history = objData.data
            console.log(history)
            if (mounted) {
                setLoading(false)
                setNextPage(history.links.next)
                setItems(history.results)
            }
        } catch (error) {
            if (mounted) {
                // if (error && error.response) {
                if (error) {
                    console.log('Error: ', error)
                    setLoading(false)
                } else {
                    console.log(true)
                    //   setError(true);
                }
            }
        }
    }

    async function asyncGetNextHistory() {
        if (nextPage) {
            const path = getNextUrl(nextPage)
            setMoreLoading(true)
            try {
                const objData = await getNextHistory(path)
                const history = objData.data
                if (mounted) {
                    setMoreLoading(false)
                    setNextPage(history.links.next)
                    setItems([...items, ...history.results])
                }
            } catch (error) {
                if (mounted) {
                    //   if (error && error.response) {
                    if (error) {
                        console.log('Error: ', error)
                        setLoading(false)
                    } else {
                        // setError(true);
                    }
                }
            }
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading && nextPage) {
                    asyncGetNextHistory()
                }
            },
            { threshold: 1 }
        )

        if (sentinelRef.current) {
            intersectionObserver.observe(sentinelRef.current)
        }

        return () => {
            intersectionObserver.disconnect()
        }
    }, [asyncGetNextHistory, loading, nextPage])

    return (
        <DashboardLayout showNavbar={false} pageTitle="Dependant - History">
            {loading ? (
                <HistoryLoader />
            ) : (
                <>
                    <Flex flexDir="column" gap={4}>
                        {items.map((item, index) => {
                            return <HistoryItem key={index} data={item} />
                        })}
                        {moreLoading && (
                            <Spinner
                                size="lg"
                                alignSelf="center"
                                mt={1}
                                color="purple.800"
                            />
                        )}
                    </Flex>
                    <div ref={sentinelRef}></div>
                </>
            )}
        </DashboardLayout>
    )
}
