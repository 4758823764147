import { ABSA_ECARD_IMAGE, DEFAULT_ECARD_IMAGE, GBG_ECARD_IMAGE, GUINNESS_ECARD_IMAGE, TPA_ECARD_IMAGE } from "./cacheImages";

export const getCardImage = (plan : string, employer: string) => {
    if(plan != 'T.  P.  A.') {
        return DEFAULT_ECARD_IMAGE
    } else {
        switch (employer) {
            case "US EMBASSY GHANA":
                return GBG_ECARD_IMAGE
            case "GUINNESS GHANA BREWERIES LTD":
                return GUINNESS_ECARD_IMAGE
            case "ABSA BANK GHANA LIMITED":
                return ABSA_ECARD_IMAGE
            default:
                return TPA_ECARD_IMAGE
        }
    }
}