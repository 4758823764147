// auth.slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginData, UserData } from '../types'

export interface User {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    memberId: string
}

export interface AuthState {
    user: User
    userData: UserData
    accessToken: string | null
    refreshToken: string | null
    isAuthenticated: boolean
    // isFirstTime: boolean
    error: string | null
    memberId: string // Add memberId
    loading: boolean
}

const initialState: AuthState = {
    user: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        memberId: '',
    },
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    // isFirstTime: true,
    error: null,
    memberId: '', // Add memberId
    loading: false,
    userData: {
        membernumber: '',
        relationship: '',
        firstname: '',
        lastname: '',
        gender: '',
        dob: '',
        age: '',
        maritalstatus: '',
        nationality: '',
        dependants: '',
        mobilephone: '',
        addresspostal: '',
        city: '',
        employer: '',
        branch: '',
        emptype: '',
        country: '',
        retage: '',
        imgperspic: '',
        schemename: '',
        policynumber: '',
        covernumber: '',
        plans: '',
        coverstatus: '',
        insured: '',
        startdate: '',
        enddate: '',
        plancode: '',
        planname: '',
        currentuser: '',
        date: '',
        printedfront: '',
        printedback: '',
        dateprinted: '',
        reprint: '',
        empregion: '',
        exiteddate: '',
        status: '',
        datecaptured: '',
        primarynumber: '',
        empstatus: '',
        datejoined: '',
        rate: '',
        spectacle: '',
        dental: '',
        inpatient: '',
        outpatient: '',
        bill: '',
        mcard: '',
        currentdate: '',
        maternity: '',
        ptype: '',
        optical: '',
        bioupdate: '',
        chronic: '',
        vit: '',
        gbg: '',
        aetna: '',
        rfid: '',
        id: 0,
        expo_token: '',
    },
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload
        },
        setLoginData: (state, action: PayloadAction<LoginData>) => {
            state.user.memberId = action.payload.memberId
            state.user.phoneNumber = action.payload.phoneNumber
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        setUserData: (state, action: PayloadAction<UserData>) => {
            state.userData = action.payload
        },
        setTokens: (
            state,
            action: PayloadAction<{ accessToken: string; refreshToken: string }>
        ) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        setLogout: (state) => {
            state.accessToken = null
            state.refreshToken = null
            state.user = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                memberId: '',
            }
            state.memberId = '' // Reset memberId on logout
        },
        setAuthError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload
        },
        setmemberId: (state, action: PayloadAction<string>) => {
            state.memberId = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
})

export const {
    setIsAuthenticated,
    setUser,
    setUserData,
    setTokens,
    setLogout,
    setAuthError,
    setmemberId,
    setLoading,
    setLoginData,
} = authSlice.actions

export default authSlice.reducer
