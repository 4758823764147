import React, { useState } from 'react'
import {
    Box,
    Select,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Switch,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    // useDisclosure,
    Text,
    Button,
} from '@chakra-ui/react'
import { MdOutlineRefresh, MdOutlineWaterDrop } from 'react-icons/md'
import TimePicker from '../../../pages/Water/_components/TimePicker'
import { useDispatch } from 'react-redux'

import {
    setIntakeGoal,
    setNotifications,
    setUnit,
} from '../../../store/water.slice'
import { useSelector } from '../../../hooks/useSelector'

interface SettingsProps {}

const SettingsTab: React.FC<SettingsProps> = () => {
    const dispatch = useDispatch()

    const [tempIntakeGoal, setTempIntakeGoal] = useState<number>(2000)
    const [tempNotifactions, setTempNotifactions] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState(false)

    // states
    const unit = useSelector((state) => state.water.unit)
    const intakeGoal = useSelector((state) => state.water.intakeGoal)

    
    const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUnit(event.target.value as 'ml' | 'kg')
        console.log(event.target.value)
        dispatch(setUnit(event.target.value))
    }
    
    const handleIntakeGoalChange = (value: number) => {
        setTempIntakeGoal(value)
    }
    
    const handleNotificationsChange = () => {
        setTempNotifactions((prevValue) => !prevValue)
        dispatch(setNotifications(tempNotifactions))
    }

    const handleOpenModal = () => {
        setIsOpen(true) // Open the modal
        setTempIntakeGoal(intakeGoal) // Initialize temporary intake goal value with the previously saved value
    }

    const handleRefreshGoal = () => {
        setTempIntakeGoal(2000)
    }

    const handleSave = () => {
        // Add logic to save settings
        dispatch(setIntakeGoal(tempIntakeGoal))
        setIsOpen(false)
    }

    const handleCancel = () => {
        // Add logic to cancel changes
        setIsOpen(false)
    }

    const units = [
        {
            label: 'ml',
            value: 'ml',
        },
        {
            label: 'kg',
            value: 'kg',
        },
    ]

    return (
        <Box
            py={8}
            px={10}
            borderWidth="1px"
            borderRadius="10px"
            color="black"
            bg="bg.100"
            w={{ base: 'full', md: '80vw' }}
            mt={4}
        >
            <Flex
                justify="space-between"
                mb={4}
                borderBottom="0.5px solid"
                borderColor="gray.150"
                pb={1}
            >
                <label htmlFor="unit">Unit</label>
                <Select
                    id="unit"
                    value={unit}
                    onChange={handleUnitChange}
                    variant="unstyled"
                    w={90}
                    aria-label="unit"
                >
                    {units.map((item, index) => (
                        <option value={item.value} key={item.label + index}>
                            {item.label}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Flex
                justify="space-between"
                mb={4}
                borderBottom="0.5px solid"
                borderColor="gray.150"
                pb={1}
            >
                <label htmlFor="intakeGoal">Intake Goal:</label>
                <Box display="flex" alignItems="center">
                    <Box
                        onClick={handleOpenModal}
                        cursor="pointer"
                        color="secondary.400"
                    >
                        {intakeGoal} {unit}
                    </Box>
                </Box>
                <Modal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    isCentered
                >
                    <ModalOverlay />
                    <ModalContent w="95vw" borderRadius="14px">
                        <ModalHeader fontSize="14px">
                            Adjust intake goals
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody
                            justifyContent="center"
                            display="flex"
                            flexDir="column"
                        >
                            <Text
                                textAlign="center"
                                color="secondary.400"
                                fontSize="22px"
                                fontWeight="bold"
                            >
                                {tempIntakeGoal} ml
                            </Text>
                            <Slider
                                defaultValue={intakeGoal}
                                min={0}
                                max={5000}
                                step={100}
                                value={tempIntakeGoal}
                                onChange={handleIntakeGoalChange}
                            >
                                <SliderTrack bg="gray.200">
                                    <SliderFilledTrack bg="bg.200" />
                                </SliderTrack>
                                <SliderThumb boxSize={12}>
                                    <Box
                                        color="bg.200"
                                        as={MdOutlineWaterDrop}
                                        boxSize={5}
                                    />
                                </SliderThumb>
                            </Slider>
                            <Button
                                variant="ghost"
                                ml={2}
                                aria-label="Recommended"
                                onClick={handleRefreshGoal}
                                rightIcon={<MdOutlineRefresh />}
                                fontSize="13px"
                            >
                                Recommended
                            </Button>
                            <Box p={4} textAlign="right">
                                <Button
                                    mr={2}
                                    onClick={handleCancel}
                                    variant="ghost"
                                    size="sm"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme="orange"
                                    onClick={handleSave}
                                    variant="ghost"
                                    size="sm"
                                >
                                    Save
                                </Button>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Flex>

            <Flex
                justify="space-between"
                mb={4}
                borderBottom="0.5px solid"
                borderColor="gray.150"
                pb={1}
            >
                <label htmlFor="sleepTime">Sleep Time</label>
                <TimePicker type="sleep" />
            </Flex>
            <Flex
                justify="space-between"
                mb={4}
                borderBottom="0.5px solid"
                borderColor="gray.150"
                pb={1}
            >
                <label htmlFor="wakeUpTime">Wake Up Time</label>
                <TimePicker type="wakeUp" />
            </Flex>

            <Flex
                justify="space-between"
                mb={4}
                borderBottom="0.5px solid"
                borderColor="gray.150"
                pb={1}
            >
                <label>Notifications</label>
                <Switch
                    id="notifications"
                    colorScheme="yellow"
                    isChecked={tempNotifactions}
                    onChange={handleNotificationsChange}
                    size="lg"
                />
            </Flex>
        </Box>
    )
}

export default SettingsTab
