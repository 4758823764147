import { Box, Center } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import loginBackground from '../../src/assets/background/login_bg.png'
import { Logo } from '../components/ui'

export default function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      backgroundImage={`url(${loginBackground})`}
      h="100vh"
      backgroundSize="contain"
    >
      <Box>
        <Center flexDir="column" height="100vh" position="relative">
          <Box pb={{ base: "30vh", md: 10 }}>
            <Logo />
          </Box>
          {children}
        </Center>
      </Box>
    </Box>
  );
}
