import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { ServiceEndpoints } from '../utils'
import useStore from '../store'
import { API_DOMAIN } from '../constants/config'
import { getRefreshToken } from './auth.service'
import { setIsAuthenticated, setLogout, setTokens } from '../store/auth.slice'

const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL || API_DOMAIN,
    responseType: 'json',
    timeout: 40000,
}

const axios = Axios.create(config)

const url_exceptions = [
    ServiceEndpoints.GET_OTP,
    ServiceEndpoints.LOGIN_WITH_OTP,
    ServiceEndpoints.LOGIN,
]
// Request Interceptor
axios.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
    // Modify URL to HTTPS if it's HTTP
    if (config.url?.startsWith('http://')) {
        config.url = config.url.replace(/^http:\/\//i, 'https://')
    }

    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    if (!url_exceptions.includes(config.url || '')) {
        config.headers.Authorization = `Bearer ${
            useStore.getState()?.auth?.accessToken
        }`
        config.headers.Accept = 'application/json'
    }
    return config
})

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lies within the range of 2xx causes this function to trigger
        // Do something with response data
        return response
    },
    async function (error) {
        if (
            error.config.url === ServiceEndpoints.REFRESH &&
            error.response.status == 401
        ) {
            useStore.dispatch(setIsAuthenticated(false))
            useStore.dispatch(setLogout())
            return Promise.reject(error)
        }

        if (
            error.response &&
            error.response.status === 401 &&
            !error.config._retry
        ) {
            error.config._retry = true
            const refreshToken = useStore.getState().auth.refreshToken
            if (refreshToken) {
                try {
                    const refreshData = await getRefreshToken(refreshToken)
                    const newAccessToken = refreshData.access
                    const newRefreshToken = refreshData.refresh

                    // Update tokens
                    useStore.dispatch(
                        setTokens({
                            accessToken: newAccessToken,
                            refreshToken: newRefreshToken,
                        })
                    )

                    // Retry the original request with updated tokens
                    error.config.headers.Authorization = `Bearer ${newAccessToken}`
                    return axios(error.config)
                        .then((response) => {
                            // Reload the page after successfully refreshing tokens and retrying request
                            window.location.reload()
                            return response
                        })
                        .catch((retryError) => {
                            console.error(
                                'Failed to retry request:',
                                retryError
                            )
                            throw retryError
                        })
                } catch (refreshError) {
                    console.error('Failed to refresh token:', refreshError)
                    throw refreshError
                }
            } else {
                // If no refreshToken, logout the user
                useStore.dispatch(setIsAuthenticated(false))
                useStore.dispatch(setLogout())
            }
        }
        return Promise.reject(error)
    }
)

export { axios }
