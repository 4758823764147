import { AxiosResponse } from 'axios'
import { loginResponseData } from '../types'
import { ServiceEndpoints } from '../utils/constants'
import { axios } from './apiConfig'
import Axios from 'axios'
import { API_DOMAIN } from '../constants/config'

export const login = async (memberId: string) => {
    return axios
        .post(ServiceEndpoints.LOGIN, {
            memberId,
            expo_token: '',
        })
        .then(({ data }) => {
            return Promise.resolve(data)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const getRefreshToken = async (refreshToken: string) => {
    return axios
        .post(ServiceEndpoints.REFRESH, {
            refresh: refreshToken,
        })
        .then(({ data }) => {
            return Promise.resolve(data)
        })
        .catch((err) => {
            return Promise.reject(err)
        })
}

export const getUser = async () => {
    return axios
        .get(ServiceEndpoints.USER)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getUserByAccessToken = async (accessToken: string) => {
    return Axios.get(API_DOMAIN + ServiceEndpoints.USER_INFO, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getOTP = async (memberId: string) => {
    return axios
        .post(`${ServiceEndpoints.GET_OTP}`, {
            memberId,
            isFirstTime: false,
            expo_token: '',
        })
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const loginWithOTP = async (
    memberId: string,
    OTP: string
): Promise<loginResponseData> => {
    try {
        const response: AxiosResponse<loginResponseData> = await axios.post(
            ServiceEndpoints.LOGIN_WITH_OTP,
            { memberId, OtpCode: OTP }
        )
        // console.log('API Response:', response)
        return response.data
        // eslint-disable-next-line
    } catch (error: any) {
        console.error('API Error:', error)
        return Promise.reject(error)
    }
}
