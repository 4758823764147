import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Flex, Input, Spinner } from '@chakra-ui/react'
import { HistoryItem } from './_components'
import {
    getNextHistory,
    getNextSearchHistory,
    getUserHistory,
    searchHistory,
} from '../../services'
import { HistoryLoader } from '../../components/ui'
import { getNextUrl } from '../../utils'
import { HistoryDetail } from '../../types'

export const HistoryPage = () => {
    const [loading, setLoading] = useState(true)
    const [mounted, setMounted] = useState(true)
    const [moreLoading, setMoreLoading] = useState(false)
    const [items, setItems] = useState<HistoryDetail[]>([])
    const [nextPage, setNextPage] = useState<string>('')
    const [search, setSearch] = useState('')
    const sentinelRef = useRef<HTMLDivElement>(null)
    //   const [networkError, setNetworkError] = useState(false);

    // useEffect(() => {
    //     setMounted(true)
    //     asnycGetUserHistory()
    //     return () => {
    //         setMounted(false)
    //         setLoading(false)
    //     }
    // }, [])

    useEffect(() => {
        const getData = setTimeout(() => {
            if (search !== '') {
                searchHsp(search)
            }
        }, 2000)
        if (!search) {
            asnycGetUserHistory()
        }

        return () => {
            setMounted(false)
            setLoading(false)
            clearTimeout(getData)
        }
    }, [search])

    async function asnycGetUserHistory() {
        try {
            setLoading(true)
            const objData = await getUserHistory()
            const history = objData.data
            console.log(history)
            // if (mounted) {
            setLoading(false)
            setNextPage(history.links.next)
            setItems(history.results)
            // }
        } catch (error) {
            // if (mounted) {
            // if (error && error.response) {
            if (error) {
                console.log('Error: ', error)
                setLoading(false)
            } else {
                console.log(true)
                //   setError(true);
            }
            // }
        }
    }

    async function asyncGetNextHistory() {
        if (nextPage) {
            const path = getNextUrl(nextPage)
            setMoreLoading(true)
            try {
                let objData
                if (!search) {
                    objData = await getNextHistory(path)
                } else objData = await getNextSearchHistory(path, search)
                const history = objData.data

                setMoreLoading(false)
                setNextPage(history.links.next)
                setItems([...items, ...history.results])
            } catch (error) {
                if (error) {
                    console.log('Error: ', error)
                    setLoading(false)
                    setMoreLoading(false)
                } else {
                    console.log(true)
                }
            }
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading && nextPage) {
                    asyncGetNextHistory()
                }
            },
            { threshold: 1 }
        )

        if (sentinelRef.current) {
            intersectionObserver.observe(sentinelRef.current)
        }

        return () => {
            intersectionObserver.disconnect()
        }
    }, [asyncGetNextHistory, loading, nextPage])

    console.log(mounted)
    const searchHsp = async (keyword: string) => {
        setSearch(keyword)
        try {
            setLoading(true)
            const objData = await searchHistory(keyword)
            console.log('objData')
            const history = objData.data
            setLoading(false)
            setNextPage(history.links.next)
            setItems(history.results)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <DashboardLayout showNavbar={false} pageTitle="Dependant - History">
            <Flex flexDir="column" gap={4}>
                <Input
                    type="search"
                    variant="search"
                    placeholder="Search"
                    // value={search}
                    onChange={(event) => setSearch(event.target.value)}
                />
                {loading ? (
                    <HistoryLoader />
                ) : (
                    <>
                        {items.map((item, index) => {
                            return <HistoryItem key={index} data={item} />
                        })}

                        {moreLoading && (
                            <Spinner
                                size="lg"
                                alignSelf="center"
                                mt={1}
                                color="purple.800"
                            />
                        )}
                        <div ref={sentinelRef}></div>
                    </>
                )}
            </Flex>
        </DashboardLayout>
    )
}
