import { Box, Flex, FlexProps, IconButton, Text } from '@chakra-ui/react'
import { IoArrowBack, IoMenu } from 'react-icons/io5'
// import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

interface MobileProps extends FlexProps {
    onOpen: () => void
    showNavbar: boolean
    pageTitle?: string
}

export const MobileNav = ({
    onOpen,
    showNavbar,
    pageTitle,
    ...rest
}: MobileProps) => {
    // const user = useSelector((state: any) => state.auth.user)
    // const userName = user?.firstName + ' ' + user?.lastName
    // const navigate = useNavigate()

    const goBack = () => {
        // navigate(-1)
        history.back()
    }

    if (showNavbar) {
        // showNavbar
        return (
            <Box
                pt={{ base: 0 }}
                position="fixed"
                right="0"
                left="0"
                bg="white"
                top="0"
                zIndex="1"
            >
                <Flex
                    w={{ base: 'full' }}
                    mx="auto"
                    px={2}
                    height="12"
                    position="relative"
                    alignItems="center"
                    borderRadius={{ base: 0, lg: 8 }}
                    bg="bg.100"
                    justifyContent={{ base: 'space-between' }}
                    {...rest}
                    // border="1px solid red"

                >
                    {/* menu icon */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        gap={4}
                        alignItems="center"
                    >
                        <IconButton
                            display={{ base: 'flex', lg: 'none' }}
                            border="none"
                            onClick={onOpen}
                            size="lg"
                            borderRadius="50px"
                            fontSize="20"
                            variant="outline"
                            aria-label="open menu"
                            icon={<IoMenu />}
                        />
                    </Box>
                </Flex>
            </Box>
        )
    }

    return (
        <Box
            pt={{ base: 0 }}
            position="fixed"
            right="0"
            left="0"
            bg="bg.200"
            top="0"
            zIndex="1"
        >
            <Flex
                w={{ base: 'full' }}
                mx="auto"
                px={2}
                height="12"
                position="relative"
                alignItems="center"
                borderRadius={{ base: 0, lg: 8 }}
                bg="bg.200"
                justifyContent={{ base: 'start' }}
                {...rest}
                gap={4}
            >
                <IconButton
                    display={{ base: 'flex', lg: 'none' }}
                    border="none"
                    onClick={goBack}
                    size="lg"
                    color="white"
                    borderRadius="50px"
                    fontSize="20"
                    variant="outline"
                    aria-label="open menu"
                    icon={<IoArrowBack />}
                />
                <Text color="white" fontSize="14px">
                    {pageTitle}
                </Text>
            </Flex>
        </Box>
    )
}
