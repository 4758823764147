import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface ProfileInfoProps {
    icon: IconType; // Assuming you are using react-icons, you can import IconType from 'react-icons'
    text: string;
    color?: string
    bold?: boolean
  }

const ProfileInfoStack = ({ icon, text, color, bold } : ProfileInfoProps) => (
    <HStack spacing={4}>
        <Icon as={icon} boxSize="16px" />
        <Text fontSize="14px" color={color} fontWeight={bold ? 700 : ""} >{text}</Text>
    </HStack>
);

export default ProfileInfoStack;