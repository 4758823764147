import React from 'react'
import { Flex, Text, chakra } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../utils'

interface DependantCardProps {
    firstname: string
    lastname: string
    membernumber: string
    relationship: string
}

export const DependantCard = ({ data }: { data: DependantCardProps }) => {
    return (
        <Link
            to={`${AppRoutes.DEPENDANT_HISTORY}?membernumber=${data.membernumber}`}
        >
            <Flex
                flexDir="column"
                gap={1}
                pb={3}
                borderBottom="1px"
                borderBottomColor="gray.100"
            >
                <Text textTransform="uppercase">
                    {data.firstname} {data.lastname}
                </Text>
                <Text>
                    Relationship -{' '}
                    <chakra.span textTransform="uppercase">
                        {data.relationship}
                    </chakra.span>
                </Text>
            </Flex>
        </Link>
    )
}
