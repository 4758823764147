import { AppRoutes } from '../utils/constants'
import {
    Claims,
    Exclusions,
    Home,
    Login,
    Profile,
    OTP,
    FAQPage,
    Contact,
    Support,
    DependantPage,
    DependantHistoryPage,
    HistoryDetailsPage,
    HistoryPage,
    Hospitals,
    Benefits,
    Ecard,
    WaterReminder,
    Chat,
    Chatbot,
} from '../pages'
import { AddMedicine, MedicationPage, ViewMedicine } from '../pages/Medication'
import MedicationRequest from '../pages/MedicationRequest'

type route = {
    path: string
    component: JSX.Element
    auth?: boolean | undefined | null
    noAuth?: boolean | undefined | null
}

export const routes: route[] = [
    // auth routes
    { path: AppRoutes.LOGIN, component: <Login />, noAuth: true },
    { path: AppRoutes.OTP, component: <OTP />, noAuth: true },
    { path: AppRoutes.CHAT_BOT, component: <Chatbot />, auth: false },

    // user routes
    { path: AppRoutes.HOME, component: <Home />, auth: true },
    { path: AppRoutes.CLAIMS, component: <Claims />, auth: true },
    { path: AppRoutes.EXCLUSIONS, component: <Exclusions />, auth: true },
    { path: AppRoutes.PROFILE, component: <Profile />, auth: true },
    { path: AppRoutes.SUPPORT, component: <Support />, auth: true },
    { path: AppRoutes.FAQ, component: <FAQPage />, auth: true },
    { path: AppRoutes.CONTACT, component: <Contact />, auth: true },
    {
        path: AppRoutes.WATER_REMINDER,
        component: <WaterReminder />,
        auth: true,
    },

    // dependant routes
    { path: AppRoutes.DEPENDANTS, component: <DependantPage />, auth: true },
    {
        path: AppRoutes.DEPENDANT_HISTORY,
        component: <DependantHistoryPage />,
        auth: true,
    },

    // history routes
    { path: AppRoutes.HISTORY, component: <HistoryPage />, auth: true },
    {
        path: AppRoutes.HISTORY_DETAILS,
        component: <HistoryDetailsPage />,
        auth: true,
    },

    { path: AppRoutes.HOSPITALS, component: <Hospitals />, auth: true },
    { path: AppRoutes.BENEFITS, component: <Benefits />, auth: true },
    { path: AppRoutes.E_CARD, component: <Ecard />, auth: true },

    // medication routes
    { path: AppRoutes.ADD_MEDICATION, component: <AddMedicine />, auth: true },
    { path: AppRoutes.MEDICATIONS, component: <MedicationPage />, auth: true },
    {
        path: AppRoutes.VIEW_MEDICATION,
        component: <ViewMedicine />,
        auth: true,
    },
    // Chat and Forum Pages
    { path: AppRoutes.CHAT, component: <Chat />, auth: true },
    { path: AppRoutes.FORUM, component: <Support />, auth: true },
    {
        path: AppRoutes.MEDICATION_REQUEST,
        component: <MedicationRequest />,
        auth: true,
    },

    // Profile Page Links
    // { path: AppRoutes.TERMSOFUSAGE, component: <TermsPage />, auth: true },
    // { path: AppRoutes.PRIVACY, component: <PrivacyPage />, auth: true },
]
