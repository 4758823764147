import React, { ReactNode } from 'react'
import { Flex, Center, Card, Box } from '@chakra-ui/react'

interface AuthCardProps {
    children: ReactNode
}

export function AuthCard({ children }: AuthCardProps) {
    return (
        <Box
            bg="white"
            w={{ base: 'full', md: '450px' }}
            h={{ base: '46vh', md: '310px' }}
            p={4}
            color="white"
            position={{ base: 'absolute', md: 'relative' }}
            bottom={{ base: '0', md: 'auto' }}
            borderRadius="50px"
            borderBottomRadius={{ base: '0', md: '50px' }}
            boxShadow="lg"
        >
            <Flex justify="center" align="center" h="full">
                <Center w="100%" h="100%" py="19px" px="17px">
                    <Card w="82%" h="full" position="relative" boxShadow="none">
                        <Box h="80%">{children}</Box>
                    </Card>
                </Center>
            </Flex>
        </Box>
    )
}
