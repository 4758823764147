import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Flex, Spinner } from '@chakra-ui/react'
import { DependantCard } from './_components'
import { useSelector } from '../../hooks/useSelector'
import { getNextDependants, getUserDependants } from '../../services'
import { Dependant } from '../../types'
import { DependantLoader } from '../../components/ui'
import { getNextUrl } from '../../utils'

export const DependantPage = () => {
    const user = useSelector((state) => state.auth.userData)
    const membernumber = user.membernumber
    // const [benefits, setBenefits] = useState([]);
    // const [refreshing, setRefreshing] = React.useState(false);
    const [loading, setLoading] = useState(true)
    const [mounted, setMounted] = useState(true)
    const [moreLoading, setMoreLoading] = useState(false)
    // const [total, setTotal] = useState();
    // const [size, setSize] = useState();
    const [nextPage, setNextPage] = useState<string>('')
    const [items, setItems] = useState<Dependant[]>([])
    const sentinelRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        console.log(membernumber)
        setMounted(true)
        getBenefits()
        return () => {
            setMounted(false)
        }
    }, [])

    const getBenefits = async () => {
        setLoading(true)
        try {
            const objData = await getUserDependants()
            console.log('result data', objData)
            const _benefits = objData.data
            if (mounted) {
                setLoading(false)
                setNextPage(_benefits.links.next)
                setItems(_benefits.results)
                console.log(_benefits.results)
                console.log(items)
                console.log('Next page:', nextPage)
            }
        } catch (error) {
            //console.log(error.response)
            if (mounted) {
                // if (error && error.response) {
                /*  console.log("error")
                        console.log(error) */
                setLoading(false)
            } else {
                console.log(error)
            }
        }
        setLoading(false)
    }

    const getNextBenefits = async () => {
        if (nextPage) {
            const path = getNextUrl(nextPage)
            setMoreLoading(true)
            try {
                console.log('Getting Next ' + path)
                //alert(search_region)
                const objData = await getNextDependants(path, {
                    type: user.relationship,
                    company: user.employer,
                    iplan: user.plans,
                })
                //const objData =  await hospitalServices.getNextHospitals(path)
                const _benefits = objData.data
                console.log(_benefits)
                if (mounted) {
                    setMoreLoading(false)
                    setNextPage(_benefits.links.next)
                    //   setSize(_benefits.page_size);
                    //   setTotal(_benefits.total);
                    setItems([...items, ..._benefits.results])
                }
            } catch (error) {
                if (mounted) {
                    //   if (error && error.response) {
                    if (error) {
                        console.log('error')
                        console.log(error)
                        setLoading(false)
                        setMoreLoading(false)
                    } else {
                        console.log(error)
                        // setError(true);
                        setMoreLoading(false)
                    }
                }
            }
        } else {
            setMoreLoading(false)
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading && nextPage) {
                    getNextBenefits()
                }
            },
            { threshold: 1 }
        )

        if (sentinelRef.current) {
            intersectionObserver.observe(sentinelRef.current)
        }

        return () => {
            intersectionObserver.disconnect()
        }
    }, [getNextBenefits, loading, nextPage])

    console.log('More loading:', moreLoading)

    return (
        <DashboardLayout showNavbar={false} pageTitle="Dependants">
            <Flex gap={4} flexDir="column">
                {loading ? (
                    <DependantLoader />
                ) : (
                    <>
                        {items.map((item, index) => {
                            return <DependantCard key={index} data={item} />
                        })}
                        {moreLoading && (
                            <Spinner
                                size="lg"
                                alignSelf="center"
                                mt={1}
                                color="purple.800"
                            />
                        )}
                    </>
                )}
                <div ref={sentinelRef}></div>
            </Flex>
        </DashboardLayout>
    )
}
