import { ServiceEndpoints } from '../utils'
import { axios } from './apiConfig'

/**
 *
 * Get User hospital history
 */
export const getUserDependants = async () => {
    return axios
        .get(ServiceEndpoints.GET_DEPENDANTS)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getNextDependants = async (
    url: string,
    payload: { type: string; company: string; iplan: string }
) => {
    return axios
        .post(url, payload)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}
