import { Text } from '@chakra-ui/react'
import React from 'react'

interface CardDetailInfoProps {
    label: string
    value: string
}

const CardDetailInfo = ({ label, value }: CardDetailInfoProps) => {
    return (
        <Text color="white" fontWeight="700" fontSize="13px">
            {label} : {value}
        </Text>
    )
}

export default CardDetailInfo
