// theme.js

// 1. import `extendTheme` function
import {
    ThemeConfig,
    defineStyle,
    defineStyleConfig,
    extendTheme,
} from '@chakra-ui/react'
import { buttonTheme } from './button'

const config: ThemeConfig = {
    // initialColorMode: "light",
    useSystemColorMode: false,
}

// extend the theme
const colors = {
    bg: {
        50: 'white',
        100: '#F6F9FC',
        200: '#683191',
    },

    purple: {
        50: '#faf6fe',
        100: '#f3ebfc',
        200: '#eadbf9',
        300: '#d9bef4',
        400: '#c294ec',
        500: '#aa6ae2',
        600: '#954bd2',
        700: '#7f39b7',
        800: '#683191',
        900: '#524493',
        950: '#67319181',
    },

    accent: {
        50: '#F3534A',
    },
    secondary: { 400: '#F5A24D' },
    danger: {
        50: '#CA0B00',
    },
    tertiary: { 50: '#FFE358', 100: '#F5A24D' },
    black: { 50: '#323643' },
    gray: {
        50: '#F6F9FC',
        100: '#EEEEEE',
        150: '#DADCE0',
        200: '#C5CCD6',
        300: '#9DA3B4',
        400: '#787E97',
        500: '#555D76',
    },
    white: '#FFFFFF',
}

const primaryInput = defineStyle({
    field: {
        fontSize: '12px',
        color: 'gray.300',
        boxShadow: 'md',
        borderRadius: '22px',
        _placeholder: { color: 'gray.200' },
        px: 5,
    },
})

const search = defineStyle({
    field: {
        boxShadow: 'none',
        bg: 'gray.100',
        fontSize: '12px',
        color: 'gray.300',
        borderWidth: '1px',
        borderColor: 'gray.100',
        borderRadius: '22px',
        _placeholder: { color: 'gray.200' },
        px: 5,
    },
})

const inputTheme = defineStyleConfig({
    defaultProps: {
        variant: 'primaryInput',
        colorScheme: 'brand',
    },
    variants: { primaryInput, search },
})

const primaryTextareaTheme = defineStyle({
    fontSize: '12px',
    color: 'gray.300',
    boxShadow: 'md',
    borderRadius: '22px',
    _placeholder: { color: 'gray.200' },
    resize: 'none',
    px: 5,
})

export const textareaTheme = defineStyleConfig({
    defaultProps: {
        variant: 'primaryTextareaTheme',
    },
    variants: { primaryTextareaTheme },
})

const components = {
    Text: {
        baseStyle: () => ({
            fontSize: '12px',
        }),
    },
    Heading: {
        baseStyle: () => ({
            fontWeight: 'medium',
        }),
    },
    Button: buttonTheme,
    Input: inputTheme,
    Textarea: textareaTheme,
}

const styles = {
    global: {
        body: {
            fontFamily: `'Inter', sans-serif`,
        },
        heading: {
            fontFamily: `'Inter', sans-serif`,
        },
    },
}

const theme = extendTheme({ config, colors, components, styles })

export default theme
