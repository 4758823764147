import { ServiceEndpoints } from '../utils'
import { axios } from './apiConfig'

/**
 *
 * Get User hospital history
 */

export const getUserHistory = async () => {
    return axios
        .get(ServiceEndpoints.GET_USER_HISTORY)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

/**
 *
 * @param {String} path
 *
 * path to next data
 */
export const getNextHistory = async (path: string) => {
    return axios
        .get(path)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

/**
 *
 * @param {String} payload
 */
export const searchHistory = async (hospital: string) => {
    return axios
        .post(ServiceEndpoints.SEARCH_HISTORY, { hospital })
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

/**
 *
 * @param {String} payload
 */
export const getNextSearchHistory = async (url: string, hospital: string) => {
    return axios
        .post(url, { hospital })
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const getHistoryDetails = async (payload: string | null) => {
    return axios
        .post(ServiceEndpoints.GET_HISTORY_DETAILS, { time: payload })
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}

/**
 *
 * @param {String} membernumber
 * @returns
 */

export const getDependantHistory = async (membernumber: string) => {
    return axios
        .get(`user/dependants/${membernumber}/history/`)
        .then((response) => {
            return Promise.resolve(response)
        })
        .catch((error) => {
            return Promise.reject(error)
        })
}
