import { API_DOMAIN } from '../constants/config'
import moment from 'moment'
// import _ from 'lodash'
import { HistoryDetail } from '../types'

export const getNextUrl = (url: string) => {
    return url.replace(API_DOMAIN, '')
}

// export const debounce = (fn, delay) => {
//   let timeoutID;
//   return function () {
//     if (timeoutID) {
//       clearTimeout(timeoutID);
//     }
//     timeoutID = setTimeout(() => {
//       fn(...args);
//     }, delay);
//   };
// };

export const getDate = (date: string | null | undefined) => {
    return moment(date).format('MMMM Do YYYY')
}

export const getCardDate = (date: string) => {
    //var v_date = new Date(moment(date).format('ddd MMM DD YYYY HH:mm:ss ZZ'))
    return moment(date).format('DD/MM/YYYY')
}

export const getDaysLeft = (date: Date) => {
    return moment(date).endOf('day').fromNow()
}

export const getTotalAmt = (payload: Array<HistoryDetail>) => {
    const initialValue = 0
    const total = payload.reduce(
        (accumulator, currentValue) =>
            accumulator + Number(currentValue.amount),
        initialValue
    )
    return total
}

export const getByKeyValue = (payload: Array<HistoryDetail>, value: string) => {
    const total = payload.filter(function (item) {
        if (item.type === value) {
            return item
        }
    })
    // const total = _.filter(payload, function (o) {
    //     if (o[key] == value) return o
    // })
    return total
}

// /**
//  *
//  * @param {String} payload
//  *
//  * returns string with first letter being uppercase and the other
//  * characters being lowercase
//  */
// export const jsUcfirst = (payload) => {
//   return payload.charAt(0).toUpperCase() + payload.slice(1).toLowerCase();
// };
