import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import LogoBackground from '../../../assets/acacia.png'

export const Logo = () => {
  return (
    <Box>
      <Image
        w={{ base: 220, md: "20em" }}
        objectFit="cover"
        src={LogoBackground}
        alt="Logo"
      />
    </Box>
  );
};
