import React, { useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { CurrentItem, EmptyMedication } from './_components'
import { AppRoutes } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import { IoAdd } from 'react-icons/io5'
import { useSelector } from '../../hooks/useSelector'
import { useDispatch } from 'react-redux'
import { deleteMedication, setMedication } from '../../store/medication.slice'

export const MedicationPage = () => {
    const dispatch = useDispatch()
    const medications = useSelector((state) => state.medication.medications)
    const drawer = useDisclosure()
    const delete_modal = useDisclosure()
    const [selectedMedication, setSelectedMedication] = useState<number>(0)
    const navigate = useNavigate()

    const showDeleteModal = () => {
        drawer.onClose()
        delete_modal.onOpen()
    }

    const handleDelete = () => {
        dispatch(deleteMedication(selectedMedication))
        delete_modal.onClose()
    }

    const handleOnClick = (payload: number) => {
        drawer.onOpen()
        setSelectedMedication(payload)
    }

    const viewMedicine = () => {
        dispatch(setMedication(selectedMedication))
        navigate(AppRoutes.VIEW_MEDICATION)
    }

    return (
        <DashboardLayout
            showNavbar={false}
            pageTitle="Medication"
            showBottomNav={false}
        >
            {medications.length ? (
                <>
                    <VStack gap={4} alignItems="start">
                        <Text fontWeight={500} my={2}>
                            All Medications
                        </Text>
                        {medications.map((item, key) => {
                            return (
                                <CurrentItem
                                    medicine={item}
                                    key={key}
                                    onClick={() => handleOnClick(key)}
                                />
                            )
                        })}
                    </VStack>
                    <Drawer
                        placement="bottom"
                        onClose={drawer.onClose}
                        isOpen={drawer.isOpen}
                    >
                        <DrawerOverlay />
                        <DrawerContent borderTopRadius={12}>
                            <DrawerBody>
                                <Flex flexDir="column" gap={6} py={4}>
                                    <Text
                                        onClick={viewMedicine}
                                        cursor="pointer"
                                    >
                                        View
                                    </Text>
                                    <Text onClick={showDeleteModal}>
                                        Delete
                                    </Text>
                                </Flex>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>

                    <Modal
                        isOpen={delete_modal.isOpen}
                        onClose={delete_modal.onClose}
                        isCentered
                        size="base"
                    >
                        <ModalOverlay />
                        <ModalContent minW="300px" maxW="84%">
                            <ModalBody pt={4} mb={4}>
                                <Flex flexDir="column" gap={2}>
                                    <Heading size="sm">Delete medicine</Heading>
                                    <Text>
                                        Are you sure you want to delete{' '}
                                        {medications[selectedMedication]?.name}?
                                    </Text>
                                </Flex>
                            </ModalBody>
                            <ModalFooter>
                                <Flex justify="end" gap={8}>
                                    <Text
                                        onClick={delete_modal.onClose}
                                        cursor="pointer"
                                    >
                                        Cancel
                                    </Text>
                                    <Text
                                        onClick={() => handleDelete()}
                                        cursor="pointer"
                                    >
                                        Yes
                                    </Text>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            ) : (
                <EmptyMedication />
            )}

            <Link to={AppRoutes.ADD_MEDICATION}>
                <Button p={2} position="fixed" right={4} bottom={4}>
                    <IoAdd />
                </Button>
            </Link>
        </DashboardLayout>
    )
}
