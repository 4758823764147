import { Box, FlexProps, Icon, Flex } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { IoPersonOutline, IoCashOutline } from 'react-icons/io5'
import { LuHome } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../utils/constants'
import { PiChatsDuotone } from 'react-icons/pi'

interface BottomNavbarLinkProps {
    name: string
    icon?: IconType
    to: string
}

const BottomNavbarLinks: Array<BottomNavbarLinkProps> = [
    { name: 'Home', icon: LuHome, to: AppRoutes.HOME },
    { name: 'Claims Refund', icon: IoCashOutline, to: AppRoutes.CLAIMS },
    { name: 'Profile', icon: IoPersonOutline, to: AppRoutes.PROFILE },
    { name: 'Support', icon: PiChatsDuotone, to: AppRoutes.SUPPORT },
]

const BottomNavbarItem = ({ icon, to }: BottomNavbarLinkProps) => {
    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <Icon as={icon} size="lg" color="gray" />
        </Link>
    )
}

interface BottomNavbarProps extends FlexProps {
    show?: boolean
}

export const BottomNavbar = ({ show = true, ...rest }: BottomNavbarProps) => {
    return (
        <Box
            pt={{ base: 0 }}
            position="fixed"
            right="0"
            left="0"
            bg="white"
            bottom="0"
            zIndex="1"
            display={show ? 'block' : 'none'}
        >
            <Flex
                w={{ base: 'full' }}
                mx="auto"
                px={6}
                height="16"
                position="relative"
                alignItems="center"
                borderRadius={{ base: 0, lg: 8 }}
                bg="bg.100"
                justifyContent={{ base: 'space-between' }}
                {...rest}
            >
                {BottomNavbarLinks.map((item, index) => {
                    return (
                        <BottomNavbarItem
                            key={index}
                            to={item.to}
                            icon={item.icon}
                            name={item.name}
                        />
                    )
                })}
            </Flex>
        </Box>
    )
}
