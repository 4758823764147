import React, { useState } from 'react'
import { Spinner, Center, Box } from '@chakra-ui/react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { MEDICATION_REQUEST_URI } from '../../constants/config'
import { useSelector } from '../../hooks/useSelector'

const MedicationRequest: React.FC = () => {
    const [visible, setVisible] = useState(true)
    const accessToken = useSelector((state) => state.auth.accessToken)

    const showSpinner = (payload: boolean) => {
        setVisible(payload)
    }

    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Medication Request"
        >
            <Box position="relative" mt="-16px">
                <iframe
                    src={`${MEDICATION_REQUEST_URI}?token=${accessToken}`}
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                    onLoad={() => showSpinner(false)}
                />
                {visible && (
                    <Center h="20vh">
                        <Spinner size="lg" color="purple.700" />
                    </Center>
                )}
            </Box>
        </DashboardLayout>
    )
}

export default MedicationRequest
