import {
    Box,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { MobileNav, SideBarContent } from '../components/ui'

interface DashboardLayoutProps {
    children: ReactNode
    paddingX?: boolean
    showNavbar?: boolean
    pageTitle?: string
    showBottomNav?: boolean
}

export default function DashboardLayout({
    children,
    paddingX = true,
    showNavbar = true,
    pageTitle,
    showBottomNav,
    ...rest
}: DashboardLayoutProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    console.log(showBottomNav)
    return (
        <Box minH="100dvh" bg="bg.100">
            <SideBarContent
                position="fixed"
                display={{ base: 'none', lg: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
                preserveScrollBarGap
                colorScheme="telegram"
            >
                <DrawerOverlay />
                <DrawerContent bg="none" onClick={onClose}>
                    <SideBarContent />
                </DrawerContent>
            </Drawer>

            <MobileNav
                onOpen={onOpen}
                showNavbar={showNavbar}
                pageTitle={pageTitle}
            />

            <Box bg="bg.100" h="100%">
                <Box
                    w={{ base: 'full', lg: '90%', xl: '80%' }}
                    px={paddingX ? 4 : 0}
                    py={{ base: 16, md: 24 }}
                    pb={{ base: 20, md: 24 }}
                    {...rest}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
