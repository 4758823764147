import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { withExtraArgument } from 'redux-thunk'
import authReducer, { AuthState } from './auth.slice'
import medicationReducer, { MedicationState } from './medication.slice'
import hospitalReducer from './hospital.slice'
import waterReducer, { WaterState } from './water.slice'
import { HospitalState } from '../types'

// Root reducer
const rootReducers = combineReducers({
    auth: authReducer,
    medication: medicationReducer,
    hospital: hospitalReducer,
    water: waterReducer, 
})

// Redux-Persist configuration
const persistConfig = {
    key: 'root',
    storage,
}

// Root state type
type RootState = ReturnType<typeof rootReducers>

// Extra argument interface
interface MyExtraArgument {
    auth: AuthState
    medication: MedicationState
    hospital: HospitalState
    water : WaterState
}

// Middleware configuration
type MyThunkMiddleware = Middleware<MyExtraArgument, RootState>

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducers)

// Configure store
const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(withExtraArgument(null) as MyThunkMiddleware),
})

// Create persistor
export const persistor = persistStore(store)

export default store
