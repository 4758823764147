import {
    Box,
    Center,
    Flex,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    Spinner,
    Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { IconType } from 'react-icons'
import { IoCashOutline } from 'react-icons/io5'
import { FaClockRotateLeft, FaHospital, FaWrench } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { AppRoutes, NO_HEALTH_TIP_IMAGE, toast } from '../../utils'
import { useSelector } from '../../hooks/useSelector'
import { useDispatch } from 'react-redux'
import { getHealthTips } from '../../services/home.service'
import { HealthTipCard } from '../../components/ui/Cards/HealthTipCard'
import { IoIosChatboxes } from 'react-icons/io'

const Activities = [
    { image: 'water.png', text: 'Water Intake', to: AppRoutes.WATER_REMINDER },
    { image: 'medication.png', text: 'Medication', to: AppRoutes.MEDICATIONS },
    { image: 'dr_acacia.png', text: 'Support', to: AppRoutes.SUPPORT },
    { icon: IoIosChatboxes, text: 'Forum', to: AppRoutes.SUPPORT },
]

const Tabs = [
    { icon: FaHospital, text: 'Hospitals', to: AppRoutes.HOSPITALS },
    { icon: FaWrench, text: 'Support', to: AppRoutes.SUPPORT },
    { icon: FaClockRotateLeft, text: 'History', to: AppRoutes.HISTORY },
    { icon: IoCashOutline, text: 'Claims', to: AppRoutes.CLAIMS },
]

export function Home() {
    interface HealthTip {
        id: number
        head: string
        body: string
        img: string
        url: string
        author: string
        author_img: string
    }

    const [loading, setLoading] = useState(true)
    const [healthTips, setHealthTips] = useState<HealthTip[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchHealthTips = async () => {
            try {
                setLoading(true)
                const healthTipsResponse = await getHealthTips()
                const healthTipsData = healthTipsResponse.data
                console.log(healthTipsData)

                setHealthTips(healthTipsData)
                setLoading(false)

                // Dispatch your Redux actions or handle the health tips data as needed
            } catch (error) {
                // console.error('Error:', error)
                toast({
                    description: "Couldn't get Health Tips!",
                    status: 'error',
                })
            } finally {
                setLoading(false)
            }
        }

        fetchHealthTips()
    }, [dispatch])

    const user = useSelector((state) => state.auth.user)
    return (
        <DashboardLayout paddingX={false}>
            <Flex
                flexDir={'column'}
                justifyContent={'space-between'}
                gap={4}
                px={4}
                overflowX={'hidden'}
                pb={4}
            >
                <Box>
                    <Text color={'#9A9BA0'} fontSize={'20px'}>
                        Hello,
                    </Text>
                    <Text
                        color={'#9A9BA0'}
                        fontSize={'2xl'}
                        fontWeight={'bold'}
                        textTransform={'capitalize'}
                    >
                        {`${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`}
                    </Text>
                </Box>

                <Flex flexDir={'row'} justifyContent={'space-between'} gap={4}>
                    {Tabs.map((item, index) => {
                        return (
                            <Tab
                                key={index}
                                icon={item.icon}
                                text={item.text}
                                to={item.to}
                            />
                        )
                    })}
                </Flex>
                {/* Health tips section */}
                <Flex
                    flexDir={'column'}
                    gap={2}
                    justifyContent={'start'}
                    w={'100%'}
                >
                    <Text fontWeight={'bold'}>Health Tips</Text>
                    <HStack
                        justifyContent={'space-between'}
                        whiteSpace={'nowrap'}
                        minW={'100dvw'}
                        gap={4}
                        overflowX={'auto'}
                        p={2}
                        pr={8}
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '&::-webkit-scrollbar-track': {
                                display: 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                display: 'none',
                                borderRadius: '24px',
                            },
                        }}
                    >
                        {loading && (
                            <Center width="100vw">
                                <Spinner size="lg" color="purple.700" />
                            </Center>
                        )}
                        {healthTips.length == 0 && loading == false && (
                            <Center width="100vw" gap={4}>
                                <Image
                                    src={NO_HEALTH_TIP_IMAGE}
                                    width={70}
                                    height={70}
                                />
                                <Text>No Health Tips</Text>
                            </Center>
                        )}
                        {healthTips &&
                            healthTips.map((item, index) => {
                                return (
                                    <HealthTipCard
                                        key={index}
                                        bgImg={item.img}
                                        title={item.head}
                                        avatar={item.author_img}
                                        name={item.author}
                                        url={item.url}
                                    />
                                )
                            })}
                    </HStack>
                </Flex>
                {/* activities section */}
                <Box>
                    <Text fontWeight={'bold'}>Activities</Text>

                    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                        {Activities.map((item, index) => {
                            return (
                                <ActivityCard
                                    key={index}
                                    image={item.image}
                                    text={item.text}
                                    to={item.to}
                                    icon={item.icon}
                                />
                            )
                        })}
                    </SimpleGrid>
                </Box>
            </Flex>
        </DashboardLayout>
    )
}

interface TabProps {
    icon: IconType
    text: string
    to: string
}

const Tab = ({ icon, text, to }: TabProps) => {
    return (
        <Link to={to}>
            <Flex
                flexDir={'column'}
                justifyContent={'center'}
                alignContent={'space-around'}
                gap={1}
            >
                <Flex
                    alignContent={'center'}
                    justifyContent={'center'}
                    p={5}
                    borderRadius={6}
                    bg={'purple.700'}
                >
                    <Icon as={icon} w={5} h={5} color={'white'} />
                </Flex>
                <Text fontSize={'10px'} textAlign={'center'}>
                    {text}
                </Text>
            </Flex>
        </Link>
    )
}

interface ActivityCardProps {
    image?: string
    icon?: IconType
    text: string
    to: string
}

const ActivityCard = ({ image, text, to, icon }: ActivityCardProps) => {
    return (
        <Link to={to}>
            <Flex
                h={'200px'}
                shadow="md"
                borderRadius={15}
                flexDir={'column'}
                gap={2}
                justifyContent={'center'}
                alignItems={'center'}
            >
                {image && (
                    <Image
                        src={require(`../../assets/icons/${image}`)}
                        alt={image}
                        boxSize={'100px'}
                        objectFit={'contain'}
                    />
                )}
                {icon && (
                    <Icon
                        as={icon}
                        boxSize={{ base: '80px', md: '100px' }}
                        objectFit="contain"
                        color="purple.800"
                    />
                )}
                <Text>{text}</Text>
            </Flex>
        </Link>
    )
}
