import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setSleepTime, setWakeUpTime } from '../../../store/water.slice';
import { useSelector } from '../../../hooks/useSelector';

interface TimePickerProps {
  type: 'wakeUp' | 'sleep';
}

const TimePicker: React.FC<TimePickerProps> = ({ type }) => {
  const dispatch = useDispatch();
  const [selectedHour, setSelectedHour] = useState<number>(0);
  const [selectedMinute, setSelectedMinute] = useState<number>(0);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // Fetch sleep and wake up times from Redux store
  const sleepTime = useSelector((state) => state.water.sleepTime);
  const wakeUpTime = useSelector((state) => state.water.wakeUpTime);

  useEffect(() => {
    // Load saved time from Redux store on component mount
    // No need to load from localStorage anymore
  }, [type]);

  const handleSaveTime = () => {
    const selectedTime = `${selectedHour.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}`;
    if (type === 'wakeUp') {
      dispatch(setWakeUpTime(selectedTime));
    } else if (type === 'sleep') {
      dispatch(setSleepTime(selectedTime));
    }
    setModalOpen(false);
  };

  return (
    <Flex direction="column" align="center">
      <Text fontSize="18px" color="secondary.400" onClick={() => setModalOpen(true)} cursor="pointer">
        {type === 'wakeUp' ? (wakeUpTime || "00:00") : (sleepTime || "00:00")}
      </Text>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent w="90vw">
          <ModalHeader>Select {type === 'wakeUp' ? 'Wake Up' : 'Sleep'} Time</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack align="center">
              <Select
                value={selectedHour}
                onChange={(e) => setSelectedHour(parseInt(e.target.value, 10))}
              >
                {[...Array(24)].map((_, hour) => (
                  <option key={hour} value={hour}>
                    {hour.toString().padStart(2, '0')}
                  </option>
                ))}
              </Select>
              <Select
                value={selectedMinute}
                onChange={(e) => setSelectedMinute(parseInt(e.target.value, 10))}
              >
                {[...Array(60)].map((_, minute) => (
                  <option key={minute} value={minute}>
                    {minute.toString().padStart(2, '0')}
                  </option>
                ))}
              </Select>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveTime}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default TimePicker;
