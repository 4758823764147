import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    VStack,
    HStack,
} from '@chakra-ui/react'
import {
    VOLUME_100_ML_IMAGE,
    VOLUME_200_ML_IMAGE,
    VOLUME_300_ML_IMAGE,
    VOLUME_400_ML_IMAGE,
    VOLUME_500_ML_IMAGE,
} from '../../../utils'
import VolumeButton from '../../../pages/Water/_components/VolumeButton'
import WaterCard from '../Cards/WaterCard'
import { useSelector } from '../../../hooks/useSelector'
import { useDispatch } from 'react-redux'
import {
    setIsCompleted,
    setPercentage,
    setWaterDrank,
} from '../../../store/water.slice'

interface WaterTabProps {
    defaultVolume: number
}

export interface DailyData {
    waterDrank: number
    percentage: number
    isCompleted: boolean
    dayOfWeek: string
}

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const WaterTab = ({ defaultVolume }: WaterTabProps) => {
    const dispatch = useDispatch()

    const goal = useSelector((state) => state.water.intakeGoal)
    const unit = useSelector((state) => state.water.unit)
    const percentage = useSelector((state) => state.water.percentage)
    const waterDrank = useSelector((state) => state.water.waterDrank)

    const [isModalOpen, setModalOpen] = useState(false)
    const [selectedVolume, setSelectedVolume] = useState<number>(defaultVolume)
    const [weeklyData, setWeeklyData] = useState<{ [key: string]: DailyData }>(
        {}
    )

    console.log(weeklyData)
    console.log(waterDrank)

    const openModal = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    const handleVolumeSelect = (volume: number) => {
        setSelectedVolume(volume)
        closeModal()
    }

    const handleDrinkWater = () => {
        const newWaterDrank: number = waterDrank + selectedVolume
        let calculatedPercentage = Math.round((newWaterDrank / goal) * 100)
        calculatedPercentage = Math.min(calculatedPercentage, 100)

        const isCompleted = calculatedPercentage === 100
        dispatch(setIsCompleted(isCompleted))

        dispatch(setWaterDrank(newWaterDrank))
        dispatch(setPercentage(calculatedPercentage))

        const dayOfWeek = daysOfWeek[new Date().getDay()]
        const dailyData: DailyData = {
            waterDrank: newWaterDrank,
            percentage: calculatedPercentage,
            isCompleted: isCompleted,
            dayOfWeek: dayOfWeek,
        }
        localStorage.setItem(dayOfWeek, JSON.stringify(dailyData))

        setWeeklyData((prevData) => ({
            ...prevData,
            [dayOfWeek]: dailyData,
        }))

        console.log(`Drinking ${selectedVolume}ml of water!`)
        console.log(`Water Drank: ${newWaterDrank}ml`)
        console.log(`Percentage: ${calculatedPercentage}%`)
    }

    useEffect(() => {
        const dayOfWeek = daysOfWeek[new Date().getDay()]
        const storedDataString = localStorage.getItem(dayOfWeek)
        if (storedDataString) {
            const storedData = JSON.parse(storedDataString)
            setWeeklyData((prevData) => ({
                ...prevData,
                [dayOfWeek]: {
                    ...prevData[dayOfWeek],
                    waterDrank: storedData.waterDrank,
                    percentage: storedData.percentage,
                    isCompleted: storedData.isCompleted,
                },
            }))
        }
    }, [])

    // clear local storage on Sunday
    useEffect(() => {
        const today = new Date()
        const currentDayOfWeek = today.getDay() // Returns a value between 0 (Sunday) and 6 (Saturday)

        // Check if the current day is Sunday (day 0)
        if (currentDayOfWeek === 0) {
            // Check if data has already been cleared on Sunday this week
            const clearedOnSunday = localStorage.getItem('clearedOnSunday')
            if (!clearedOnSunday) {
                // Reset local storage to initial values
                daysOfWeek.forEach((day) => {
                    const initialData: DailyData = {
                        waterDrank: 0,
                        percentage: 0,
                        isCompleted: false,
                        dayOfWeek: day,
                    }
                    localStorage.setItem(day, JSON.stringify(initialData))
                })

                // Set the flag to indicate that data has been cleared on Sunday
                localStorage.setItem('clearedOnSunday', 'true')
            }
        } else {
            // If it's not Sunday, remove the flag
            localStorage.removeItem('clearedOnSunday')
        }
    }, [])

    // Reset stored data at the beginning of each new day
    useEffect(() => {
        const dayOfWeek = daysOfWeek[new Date().getDay()]
        const storedDataString = localStorage.getItem(dayOfWeek)

        if (!storedDataString) {
            dispatch(setWaterDrank(0))
            dispatch(setPercentage(0))
            dispatch(setIsCompleted(false))
        } else {
            const storedData = JSON.parse(storedDataString)
            dispatch(setWaterDrank(storedData.waterDrank))
            dispatch(setPercentage(storedData.percentage))
            dispatch(setIsCompleted(storedData.isCompleted))
        }

        console.log(dayOfWeek)
    }, [])

    const volumeButtons = [
        {
            volume: 100,
            imageSrc: VOLUME_100_ML_IMAGE,
            onSelect: handleVolumeSelect,
        },
        {
            volume: 200,
            imageSrc: VOLUME_200_ML_IMAGE,
            onSelect: handleVolumeSelect,
        },
        {
            volume: 300,
            imageSrc: VOLUME_300_ML_IMAGE,
            onSelect: handleVolumeSelect,
        },
        {
            volume: 400,
            imageSrc: VOLUME_400_ML_IMAGE,
            onSelect: handleVolumeSelect,
        },
        {
            volume: 500,
            imageSrc: VOLUME_500_ML_IMAGE,
            onSelect: handleVolumeSelect,
        },
    ]

    return (
        <Box>
            <Flex align="center" justify="center" h="85vh">
                <Flex flexDirection="column" justify="center" align="center">
                    <HStack justify="space-between" color="black" w="100%">
                        <WaterCard percentage={percentage} />
                        <VStack align="start" gap="2px" w="5.5rem" ml={8}>
                            <Text fontSize="30px">{percentage} %</Text>
                            <Text color="secondary.400" fontSize="14px">
                                Drank
                            </Text>
                            <Text
                                color="secondary.400"
                                fontSize="18px"
                                textTransform="uppercase"
                            >
                                {selectedVolume}ml
                            </Text>
                            <Text fontSize="14px">GOAL</Text>
                            <Text fontSize="14px" fontWeight="bolder">
                                {goal} {unit}
                            </Text>
                        </VStack>
                    </HStack>
                    <HStack mt={7}>
                        <Button
                            variant="primary"
                            bg="secondary.400"
                            textTransform="uppercase"
                            onClick={handleDrinkWater}
                        >
                            Drink Water
                        </Button>
                        <Button
                            variant="outline"
                            borderColor="secondary.400"
                            bg="bg.100"
                            color="black"
                            p={4}
                            textTransform="uppercase"
                            onClick={openModal}
                        >
                            {selectedVolume}ml
                        </Button>
                    </HStack>
                </Flex>
            </Flex>

            {/* Modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                size="sm"
                isCentered
            >
                <ModalOverlay />
                <ModalContent w="93%">
                    <ModalHeader fontSize="14px">Select Volume</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            align="center"
                            flexWrap="wrap"
                            justify="center"
                            rowGap={10}
                            columnGap={3}
                            p={2}
                        >
                            {volumeButtons.map((item, index) => (
                                <VolumeButton
                                    key={item.volume + index}
                                    volume={item.volume}
                                    imageSrc={item.imageSrc}
                                    onSelect={item.onSelect}
                                />
                            ))}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default WaterTab
