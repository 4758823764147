import { Flex, Icon, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { IconType } from 'react-icons'

interface HistoryCardProps {
    title: string
    icon: IconType
    children: ReactNode
    onClick?: () => void
}

export const HistoryCard = ({
    icon,
    title,
    children,
    onClick,
    ...rest
}: HistoryCardProps) => {
    return (
        <Flex
            flexDir="column"
            gap={1}
            borderRadius={20}
            bg="gray.100"
            minH="10dvh"
            p={4}
            {...rest}
            onClick={onClick}
        >
            <Flex gap={2} alignItems="center" color="purple.900">
                <Icon as={icon} boxSize={8} />
                <Text>{title}</Text>
            </Flex>
            <Flex flexDir="column">{children}</Flex>
        </Flex>
    )
}
