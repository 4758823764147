import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { FaPlusCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQAccordionProps {
  faqs: FAQItem[];
  loading?: boolean;
}

const FAQAccordion = ({ faqs, loading }: FAQAccordionProps) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggleItem = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box flex={1} backgroundColor="bg.100">
      <Accordion allowToggle width="100vw">
        {faqs.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionButton onClick={() => handleToggleItem(index)}>
              <Box flex="1" textAlign="left" px={4} py={3}>
                <Text fontWeight="600">{item.question}</Text>
              </Box>
              <Icon
                as={expandedIndex === index ? IoMdCloseCircle : FaPlusCircle}
                boxSize={expandedIndex === index ? 6 : 5}
                color="purple.800"
              />
            </AccordionButton>
            <AccordionPanel p={5} pt={0}>
              <Text fontStyle="italic" color="tertiary.100" fontSize="14px">
                {item.answer}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQAccordion;
