import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const menuItems = defineStyle({
    variant: 'ghost',
    borderRadius: 'md',
    p: 2,
    textAlign: 'left',
})

const socialMediaItems = defineStyle({
    variant: 'ghost',
    _hover: { bg: 'none' },
})

const primary = defineStyle({
    bg: 'bg.200',
    color: 'white',
    size: 'md',
    _hover: { opacity: 0.7 },
    _active: { bg: '#67319181' },
    px: '40px',
    py: '18px',
    borderRadius: 50,
    fontSize: '12px',
})

const modalIcons = defineStyle({
    border: '0.5px solid black',
    borderRadius: '50%',
    p: 3,
    bg: 'white',
    borderColor: '#DADCE0',
})

const secondary = defineStyle({
    bg: 'secondary.400',
    color: 'white',
    size: 'md',
    _hover: { opacity: 0.7 },
    _active: { opacity: 0.7 },
    px: '40px',
    py: '18px',
    borderRadius: 50,
    borderColor: 'secondary.400',
    fontSize: '12px',
})

const outline = defineStyle({
    bg: 'transparent',
    size: 'md',
    _hover: { opacity: 0.7 },
    _active: { opacity: 0.7 },
    px: '40px',
    py: '18px',
    borderWidth: '1px',
    borderRadius: 50,
    borderColor: 'gray.100',
    fontSize: '12px',
})

export const buttonTheme = defineStyleConfig({
    defaultProps: {
        size: 'lg',
        variant: 'primary',
        colorScheme: 'brand',
    },
    variants: {
        primary,
        menuItems,
        modalIcons,
        secondary,
        socialMediaItems,
        outline,
    },
})
