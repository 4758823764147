import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { EXCLUSIONS_IMAGE } from '../../utils'

export function Exclusions() {
    const exclusions = [
        'Cosmetic surgery for aesthetic purposes and reconstructive procedures',
        ' Medical devices for secondary management like Hearing aids, contact lenses, braces, elastic stockings, Zimmer frames and wheel chairs and self-use medical devices e.g. BP monitors, glucometers',
        'Investigations, treatments, and surgery for obesity including slimming medications',
        'Intentional self-injury or illness resulting from alcoholism and drunkenness, drug addiction or the use of drugs not medically prescribed',
        'Treatment available as free public health services e.g. immunization, TB, Buruli Ulcer, Mental health/psychiatric conditions, VCT for HIV, Trachoma, Family planning, contraception etc.',
        'Herbal/ homeopathic treatment',
        'Medical examinations for non-medical purposes e.g. Pre-marital medical examinations, pre-employment, visa etc.',
        'Congenital abnormalities.',
        'Assisted reproduction, fertility treatment and treatment of sexual dysfunction',
        'Termination of pregnancy except where there is an immediate threat to maternal life.',
        'Treatment which involves rehabilitation other than physiotherapy',
        'Diagnosis and treatment overseas',
        'All endoscopic procedures (scoping procedures), except pre-authorized',
        'Chiropractic procedures and alternate medicine',
        'Mortuary services',
        'Any benefit not specified within the benefit option selected',
        'Over the counter medications and supplies',
        'Any expenses incurred in connection with injury or illness caused or contributed to by war or invasion or whilst engaging or taking part in military, naval or air force services or operation or riot or civil commotion.',
        'Dental exclusions –Orthodontics (cosmetic dental surgery), dentures, artificial teeth, bridges, crowns and braces.',
        'Treatment of sickness or injury by a member or dependent and for which any other party be liable, unless otherwise provided for under this policy(e.g. motor accident , workman compensation, group accident policy)',
    ]
    const dental = [
        'Orthodontics (cosmetic dental surgery)',
        'Dentures/artificial teeth',
        'Bridges',
        'Crowns',
        'Braces',
    ]
    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Exclusions"
        >
            <Flex
                flexDir={'column'}
                justifyContent={'space-between'}
                gap={3}
                px={4}
                minH="100dvh"
                pb={6}
            >
                {/* <pre>{exclusions}</pre> */}
                <Flex
                    flexDir="column"
                    maxH="150px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Image src={EXCLUSIONS_IMAGE} fill="contain" maxH="150px" />
                </Flex>
                <Heading size="sm">
                    Acacia Health Insurance shall not be liable for payments in
                    respect of the follwing:
                </Heading>

                {exclusions.map((item, index) => {
                    return (
                        <Text key={index}>
                            {'\u2022'} {item}
                        </Text>
                    )
                })}
                <Heading size="sm"> Below are the dental exclusions:</Heading>

                {dental.map((item, index) => {
                    return (
                        <Text key={index}>
                            {'\u2022'} {item}
                        </Text>
                    )
                })}
            </Flex>
        </DashboardLayout>
    )
}
