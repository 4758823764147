import { createSlice } from '@reduxjs/toolkit'

export interface WaterState {
    waterDrank: number
    isCompleted: boolean
    unit: string
    intakeGoal: number
    notifications: boolean
    percentage: number
    sleepTime: string
    wakeUpTime: string
}

const initialState: WaterState = {
    waterDrank: 0,
    isCompleted: false,
    unit: 'ml',
    intakeGoal: 2000,
    notifications: true,
    percentage: 0,
    sleepTime: '',
    wakeUpTime: '',
}

const waterSlice = createSlice({
    name: 'water',
    initialState,
    reducers: {
        setWaterDrank: (state, action) => {
            state.waterDrank = action.payload
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload
        },
        setUnit: (state, action) => {
            state.unit = action.payload
        },
        setIntakeGoal: (state, action) => {
            state.intakeGoal = action.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        setPercentage: (state, action) => {
            state.percentage = action.payload
        },
        setWakeUpTime: (state, action) => {
            state.wakeUpTime = action.payload
        },
        setSleepTime: (state, action) => {
            state.sleepTime = action.payload
        },
    },
})

export const {
    setWaterDrank,
    setIsCompleted,
    setUnit,
    setIntakeGoal,
    setNotifications,
    setPercentage,
    setSleepTime,
    setWakeUpTime,
} = waterSlice.actions

export default waterSlice.reducer
