import React from 'react'
import { Box, Text, Image, Icon, Center } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'

interface MenuItemProps {
    name: string
    to?: string
    source?: string
    icon?: IconType
}

const MenuItem = ({ name, to, icon, source }: MenuItemProps) => {
    return to ? (
        <Link to={to}>
            <Box
                py={{ base: 10, md: 16 }}
                bg="white"
                borderRadius="30px"
                boxShadow="sm"
            >
                <Center>
                    {icon ? (
                        <Icon
                            as={icon}
                            boxSize={{ base: '80px', md: '100px' }}
                            color="purple.800"
                        />
                    ) : source ? (
                        <Image
                            src={source}
                            alt={name}
                            boxSize={{ base: '80px', md: '100px' }}
                            objectFit="cover"
                        />
                    ) : null}
                </Center>
            </Box>
            <Text mt={3} textAlign="center">
                {name}
            </Text>
        </Link>
    ) : (
        <Box onClick={() => document.getElementById('zsiq_float')?.click()}>
            <Box
                py={{ base: 10, md: 16 }}
                bg="white"
                borderRadius="30px"
                boxShadow="sm"
            >
                <Center>
                    {icon ? (
                        <Icon
                            as={icon}
                            boxSize={{ base: '80px', md: '100px' }}
                            color="purple.800"
                        />
                    ) : source ? (
                        <Image
                            src={source}
                            alt={name}
                            boxSize={{ base: '80px', md: '100px' }}
                            objectFit="cover"
                        />
                    ) : null}
                </Center>
            </Box>
            <Text mt={3} textAlign="center">
                {name}
            </Text>
        </Box>
    )
}

export default MenuItem
