import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import MenuItem from './_components/MenuItem'
import DashboardLayout from '../../layouts/DashboardLayout'
import Dricon from '../../assets/icons/dricon.png'

import { PiChatsDuotone } from 'react-icons/pi'
import { IoMdHelpCircle } from 'react-icons/io'
import { MdSupportAgent } from 'react-icons/md'
import { AppRoutes } from '../../utils'
// import ZohoChatbot from '../../components/ui/ZohoChatBot'

const menuItems = [
    {
        source: Dricon,
        name: 'Support',
        to: AppRoutes.CHAT,
    },
    { to: AppRoutes.FORUM, icon: PiChatsDuotone, name: 'Forum' },
    { to: AppRoutes.CONTACT, icon: MdSupportAgent, name: 'Contact Us' },
    { to: AppRoutes.FAQ, icon: IoMdHelpCircle, name: 'FAQs' },
]

declare global {
    interface Window {
        $zoho: {
            salesiq?: {
                widgetcode: string
                values: Record<string, unknown>
                ready: () => void
            }
        }
    }
}

export function Support() {
    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Support"
        >
            <Box bg="bg.100" h="100vh" mt={-4} px={{ md: '10vw' }}>
                <Grid
                    templateColumns={{
                        base: 'repeat(2, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: 'repeat(2, 1fr)',
                    }}
                    gap={4}
                    px={4}
                    py={8}
                >
                    {menuItems.map((menuItem, index) => (
                        <MenuItem
                            key={index}
                            to={menuItem.to}
                            source={menuItem.source}
                            icon={menuItem.icon}
                            name={menuItem.name}
                        />
                    ))}
                </Grid>
            </Box>
        </DashboardLayout>
    )
}
