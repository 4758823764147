import { createStandaloneToast } from "@chakra-ui/toast";
import { toastType } from "../types";

const { toast: ChakraToast } = createStandaloneToast();

export const toast = ({
  title = "",
  description = "",
  status = "success",
}: toastType): React.ReactNode => {
  ChakraToast({
    title,
    description,
    position: "top-right",
    status,
    duration: 4000,
    isClosable: true,
  });

  return 
};
