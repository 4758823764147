import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import {
    Button,
    Flex,
    Icon,
    Input,
    Text,
    Textarea,
    chakra,
} from '@chakra-ui/react'
import { upload } from '../../hooks/useUploader'
import { IoImage } from 'react-icons/io5'
import { ImageItem } from '../../components/ui'
import { toast } from '../../utils'
import { useSelector } from '../../hooks/useSelector'
import { axios } from '../../services/apiConfig'

export const Claims = () => {
    const user = useSelector((state) => state.auth.userData)
    const [loading, setLoading] = useState<boolean>(false)
    const [images, setImages] = useState<File[]>([])
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [reason, setReason] = useState<string>('')

    useEffect(() => {
        initForm()
    }, [])
    const initForm = () => {
        setPhone(user.mobilephone)
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (images && images.length < 5 && e.target.files) {
            const files = Array.from(e.target.files)
            setImages([...images, ...files])
        } else {
            toast({
                title: 'Maximum image upload reached',
                status: 'error',
            })
        }
        console.log('Images', images)
    }

    const sendClaim = async () => {
        if (!images || images.length === 0) {
            toast({
                title: 'Please upload at least one image',
                status: 'error',
            })
            return
        }
        if (!email || !reason || !phone) {
            toast({
                title: 'One or more input fields empty',
                status: 'error',
            })
            return
        }

        const formData = new FormData()
        images.forEach((image) => {
            formData.append(`refundImages`, image)
        })
        formData.append('email', email)
        formData.append('reason', reason)
        formData.append('username', `${user.firstname} ${user.lastname}`)
        formData.append('phoneNumber', phone)
        formData.append('memberNumber', user.membernumber)
        formData.append('companyName', user.employer)
        formData.append('plan', user.plans)
        console.log('Payload', formData)

        setLoading(true)
        try {
            const response = await axios.post('/user/claimrefund/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                timeout: 100000,
            })
            console.log('response', response)
            toast({
                title: 'Request sent successfully',
                status: 'success',
            })
            setEmail('')
            setReason('')
            setImages([])
        } catch (error) {
            console.error('Error uploading claim:', error)
            toast({
                title: 'Request encountered an error',
                status: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    const deleteItem = (index: number) => {
        if (images) {
            const updatedImages = [...images]
            updatedImages.splice(index, 1)
            setImages(updatedImages)
        }
    }

    return (
        <DashboardLayout
            paddingX={false}
            showNavbar={false}
            pageTitle="Claims Refund"
        >
            <form>
                <Flex
                    flexDir="column"
                    minH="100dvh"
                    gap={7}
                    px={6}
                    justifyContent="start"
                    alignItems="center"
                    w={['100%', '80%']}
                    mx={['0', 'auto']}
                >
                    <Flex
                        flexDir="column"
                        justifyContent="center"
                        alignItems="center"
                        color="gray.300"
                        gap={1}
                        mx="1px"
                        h="100px"
                        w="100%"
                        border=" 1px dashed"
                        borderColor="gray.200"
                        borderRadius="12"
                        onClick={() => upload('image')}
                    >
                        <Icon as={IoImage} w="10" h="10" />
                        <Text>Browse to upload image</Text>
                        <chakra.input
                            id="image"
                            placeholder="Browse to upload image"
                            type="file"
                            accept="image/*,.pdf"
                            hidden
                            onChange={handleImageChange}
                        />
                    </Flex>

                    {images &&
                        images.map((image, index) => {
                            return (
                                <>
                                    <ImageItem
                                        key={index}
                                        uri={URL.createObjectURL(image)}
                                        image={URL.createObjectURL(image)}
                                        deleteItem={() => deleteItem(index)}
                                    />
                                </>
                            )
                        })}
                    <Flex
                        flexDir="column"
                        w="100%"
                        gap={4}
                        px={4}
                        justifyContent="start"
                        alignItems="center"
                    >
                        <Input
                            placeholder="Email"
                            type="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <Input
                            placeholder="0555935329"
                            id="contact"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                        />
                        <Textarea
                            placeholder="Reason"
                            rows={6}
                            cols={1}
                            id="reason"
                            value={reason}
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </Flex>
                    <Button
                        w="120px"
                        isLoading={loading}
                        type="button"
                        onClick={() => sendClaim()}
                    >
                        Send
                    </Button>
                </Flex>
            </form>
        </DashboardLayout>
    )
}
