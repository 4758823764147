import React, { useEffect, useState } from 'react'
import { Box, Circle, Flex, Icon, Text } from '@chakra-ui/react'
import { FaCircleCheck } from 'react-icons/fa6'
import { LineChart } from '../Charts/LineChart'
import { daysOfWeek } from './WaterTab'

interface DailyData {
    waterDrank: number
    percentage: number
    isCompleted: boolean
    dayOfWeek: string
}

const HistoryTab = () => {
    const [completionStatusByDay, setCompletionStatusByDay] = useState<
        boolean[]
    >([])

    useEffect(() => {
        const completionStatusArray: boolean[] = []
        daysOfWeek.forEach((day) => {
            const dailyDataString = localStorage.getItem(day)
            if (dailyDataString) {
                const dailyData: DailyData = JSON.parse(dailyDataString)
                completionStatusArray.push(dailyData.isCompleted)
            } else {
                completionStatusArray.push(false)
            }
        })
        setCompletionStatusByDay(completionStatusArray)
    }, [])

    return (
        <Box>
            <LineChart />
            <Box
                mt={8}
                p={4}
                borderWidth="1px"
                borderRadius="lg"
                w="92vw"
                bg="bg.50"
                color="black"
            >
                <Text fontWeight="bold" fontSize="sm" mb={4} align="start">
                    Weekly Completion
                </Text>
                <Flex justify="space-around">
                    {daysOfWeek.map((day) => (
                        <Box key={day} textAlign="center">
                            {completionStatusByDay[daysOfWeek.indexOf(day)] ? (
                                <Icon
                                    as={FaCircleCheck}
                                    boxSize="30px"
                                    color="secondary.400"
                                />
                            ) : (
                                <Circle size="30px" bg="secondary.400" mb={2.5} />
                            )}
                            <Text mt={1}>{day}</Text>
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Box>
    )
}

export default HistoryTab
