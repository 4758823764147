import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { HospitalCard } from './HospitalCard'
import { useSelector } from '../../../hooks/useSelector'
import { Center, VStack, Box, Text, Spinner, Skeleton } from '@chakra-ui/react'
import {
    setHospitalLoading,
    setItems,
    setNextPage,
    setRegion,
    setSelectedHospital,
} from '../../../store/hospital.slice'
import {
    getAllHospitals,
    getHospitalsByRegion,
    getNextHospitals,
    getNextHospitalsByRegion,
} from '../../../services/hospital.service'

const HospitalsList: React.FC = () => {
    const dispatch = useDispatch()
    const hospitals = useSelector((state) => state.hospital.items)
    const selectedRegion = useSelector((state) => state.hospital.searchRegion)
    const nextPageUrl = useSelector((state) => state.hospital.nextPage)
      const hospitalLoading = useSelector((state) => state.hospital.loading);
    const [loading, setLoading] = useState(false)
    const [allHospitalsLoaded, setAllHospitalsLoaded] = useState(false)
    const sentinelRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setRegion(''))
            try {
                setLoading(true)
                dispatch(setHospitalLoading(true))
                let data

                if (!selectedRegion || selectedRegion === 'All') {
                    data = await getAllHospitals()
                } else {
                    data = await getHospitalsByRegion(selectedRegion)
                }

                const items = data.data.results
                const nextPageUrl = data.data.links.next

                dispatch(setItems(items))
                dispatch(setNextPage(nextPageUrl))
                dispatch(setSelectedHospital({}))
                setAllHospitalsLoaded(false)
            } catch (error) {
                console.error('Error:', error)
            } finally {
                setLoading(false)
                dispatch(setHospitalLoading(false))
            }
        }

        fetchData()
    }, [dispatch, selectedRegion])

    const loadMoreHospitals = async () => {
        setLoading(true)

        try {
            let nextPageData

            if (!selectedRegion || selectedRegion === 'All') {
                nextPageData = await getNextHospitals(nextPageUrl)
            } else {
                nextPageData = await getNextHospitalsByRegion(
                    nextPageUrl,
                    selectedRegion
                )
            }

            const nextPageItems = nextPageData.data.results
            const nextPage = nextPageData.data.links.next

            dispatch(setItems([...hospitals, ...nextPageItems]))
            dispatch(setNextPage(nextPage))
            dispatch(setSelectedHospital({}))

            if (!nextPage) {
                setAllHospitalsLoaded(true)
            }
        } catch (error) {
            console.error('Error loading more hospitals:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                if (
                    entries[0].isIntersecting &&
                    !loading &&
                    nextPageUrl &&
                    !allHospitalsLoaded
                ) {
                    loadMoreHospitals()
                }
            },
            { threshold: 1 }
        )

        if (sentinelRef.current) {
            intersectionObserver.observe(sentinelRef.current)
        }

        return () => {
            intersectionObserver.disconnect()
        }
    }, [loadMoreHospitals, loading, nextPageUrl, allHospitalsLoaded])

    return (
        <Center>
            <VStack spacing={4} align="stretch" w="full">
                {hospitals.length === 0 ? (
                    <VStack>
                        <Text>No results found</Text>
                    </VStack>
                ) : (
                    hospitals.map((hospital, index: number) => (
                        <Skeleton key={index} isLoaded={!hospitalLoading} height="fit-content" borderRadius="10px">
                            <Box position="relative">
                                <HospitalCard hospital={hospital} />
                                {loading && index === hospitals.length - 1 ? (
                                    <Spinner
                                        size="lg"
                                        position="absolute"
                                        left={'50%'}
                                        mt={2}
                                        color="purple.800"
                                    />
                                ) : null}
                            </Box>
                        </Skeleton>
                    ))
                )}
                <div ref={sentinelRef}></div>
            </VStack>
        </Center>
    )
}

export default HospitalsList