import React from "react";
import { Box, Flex, Text, Button, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IconType } from "react-icons";

interface ContactItemProps {
  icon: IconType;
  url: string;
  route?: string;
  description: string;
  textStyle?: React.CSSProperties;
}

const ContactItem = ({
  icon,
  url,
  route,
  description,
  textStyle,
}: ContactItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (url) {
      if (route) {
        navigate(route);
      } else {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <Box paddingTop={2}>
    <Flex
      direction="row"
      backgroundColor="white"
      padding={5}
      borderRadius={10}
      cursor={url ? "pointer" : "default"}
    >
      <Box flex={0.2} justifyContent="center" alignItems="center">
        <Icon as={icon} boxSize={5} color="black" />
      </Box>
      <Box flex={1} justifyContent="center" w="80%">
        {url ? (
          <Button
            variant="link"
            onClick={handleClick}
            pr={3}
            whiteSpace="normal"
            lineHeight="normal"
            textAlign="left"
          >
            <Text style={textStyle}>{description}</Text>
          </Button>
        ) : (
          <Text>{description}</Text>
        )}
      </Box>
    </Flex>
  </Box>
  );
};

export default ContactItem;
